import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

export async function getFormElements() {
  try {
    const response = await callGetApi({ url: `form-builder/get-form-builder-elements` });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function createForm(body) {
  try {
    const response = await callPostApi({
      url: `form-builder/create-form`,
      body: body,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getFormList(body) {
  try {
    const response = await callPostApi({
      url: `form-builder/form-list`,
      body: body,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getSingleFormData(id) {
  try {
    const response = await callGetApi({
      url: `form-builder/get-form-by-id/${id}`,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateForm(body) {
  try {
    const response = await callPutApi({
      url: `form-builder/update-form`,
      body: body,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function deleteForm(id) {
  try {
    const response = await callDeleteApi({
      url: `form-builder/delete-form`,
      body: { id: id },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateFormStatus(body) {
  try {
    const response = await callPutApi({
      url: `form-builder/update-form-status`,
      body: body,
    });
    return response;
  } catch (error) {
    throw error;
  }
}