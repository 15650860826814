import { createContext } from "react";

import { LoginFormContext } from "../loginFormCtx";
import { useContext } from "react";

export const CommonContext = createContext();

export const CommonContextProvider = ({ children }) => {
  const { setIsLoading } = useContext(LoginFormContext);

  // loader spin
  const CCLoader = (val, time) => {
    if (val === false) {
      setTimeout(() => {
        setIsLoading(false);
        // document.body.style.overflow = 'auto';
      }, time ?? 1500);
    } else if (val === true) {
      // document.body.style.overflow = 'hidden';
      setIsLoading(true);
    } else {
      // document.body.style.overflow = 'hidden';
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        // document.body.style.overflow = 'auto';
      }, time ?? 1500);
    }
  };

  return (
    <CommonContext.Provider
      value={{
        CCLoader,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
