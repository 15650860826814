import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

// Get Role Permissions List API function
export async function getRolePermissions(data) {
  try {
    const response = await callPostApi({ url: "role/list", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Add Role Permissions API function
export async function addRolePermissions(data) {
  try {
    const response = await callPostApi({ url: "role/add", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Role Permissions By Id API function
export async function getRolePermissionsById(id) {
  try {
    const response = await callGetApi({ url: `role/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Delete Role Permissions API function
export async function deleteRolePermissions(id) {
  try {
    const response = await callDeleteApi({ url: `role/delete/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update Role Permissions API function
export async function updateRolePermissions(data) {
  try {
    const response = await callPutApi({ url: "role/update", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update Role Status API function
export async function updateRoleStatus(id, data) {
  try {
    const response = await callPutApi({ url: `role/update-status/${id}`, body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
