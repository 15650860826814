import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

// Get Services List API function
export async function getServices(data) {
  try {
    const response = await callPostApi({ url: "services/list", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Add Services API function
export async function addService(data) {
  try {
    const response = await callPostApi({ url: "services/add", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Services By Id API function
export async function getServiceById(id) {
  try {
    const response = await callGetApi({ url: `services/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update Services API function
export async function updateService(id, data) {
  try {
    const response = await callPutApi({ url: `services/update/${id}`, body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Delete Services API function
export async function deleteService(id) {
  try {
    const response = await callDeleteApi({ url: `services/delete/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get All Service Types API function
export async function getAllServiceTypes() {
  try {
    const response = await callGetApi({ url: "service-types/get-all-service-types" });
    return response;
  } catch (error) {
    throw error;
  }
}
