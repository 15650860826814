// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Grid } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LoginFormContext } from "context/loginFormCtx";
import { getPlanById } from "lib/Api/Plans";
import AddEditPlanForm from "components/Plans/addEditForm";

function EditPlan() {
  const { id } = useParams();

  const [planData, setPlanData] = useState(null);

  const navigate = useNavigate();

  const { setNotification } = useContext(LoginFormContext);

  const getPlanData = async () => {
    try {
      const response = await getPlanById(id);

      if (response?.status === 200) {
        const resData = response?.data?.data;
        setPlanData(resData);
      } else {
        setNotification({
          color: "error",
          icon: "check",
          title: "Error",
          content: response?.data?.message,
          open: true,
        });
        navigate("/plans");
      }
    } catch (error) {
      console.log(error);
      setNotification({
        color: "error",
        icon: "check",
        title: "Error",
        content: error?.message || "Something went wrong!",
        open: true,
      });
    }
  };

  useEffect(() => {
    getPlanData();
  }, [id]);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Edit Plan
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can edit your plan.
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            {planData && <AddEditPlanForm planData={planData} />}
          </MDBox>
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default EditPlan;
