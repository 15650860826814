import $ from "jquery"; //Load jquery
import React, { createRef, useContext, useEffect, useRef, useState } from "react"; //For react component
// import the react-json-view component
import ReactJson from "react-json-view";
import { FormBuilderContext } from "context/FormBuilderCtx";
import * as Yup from "yup";
import {
  Grid,
  Button,
  Icon,
  LinearProgress,
  CircularProgress,
  TextField,
  MenuItem,
} from "@mui/material";
import "../../style/scss/FormBuilder/index.css";
import { getFormElements } from "lib/Api/FormBuilder";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DRAG_AREA_TEXT } from "utils/constants";
import { useFormik } from "formik";
import { LoginFormContext } from "context/loginFormCtx";
import { VALIDATION_MESSAGES } from "utils/constants";
import { getServices } from "lib/Api/Services";
import { getSingleFormData } from "lib/Api/FormBuilder";
import { updateForm } from "lib/Api/FormBuilder";
// import { Button } from "@mui/base";
window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
require("formBuilder"); // For FormBuilder

const FormEditor = () => {
  const fb = useRef();
  let [formBuilder, setFormBuilder] = useState(null);
  let [fields, setFields] = useState([]);
  const { form, setForm } = useContext(FormBuilderContext);
  const { setNotification } = useContext(LoginFormContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [servicesList, setServicesList] = useState([]);

  const { id: idFromParams } = useParams();

  const navigate = useNavigate();

  var options = {
    controlPosition: "left",
    controlOrder: ["text", "select", "textarea"],
    disabledActionButtons: ["data", "save", "clear"],
    disabledFieldButtons: {
      text: ["copy"], // disables the copy button for text fields
      select: ["copy"], // disables the copy button for select fields
    },
    onOpenFieldEdit: async function (editPanel) {
      const placeholderWrap = document.getElementsByClassName("placeholder-wrap");

      const formElements = document.getElementsByClassName("form-elements");

      for (let index = 0; index < placeholderWrap.length; index++) {
        placeholderWrap[index].children[0].innerHTML = "Display Text";
      }

      for (let index = 0; index < formElements.length; index++) {
        const createMinLengthDiv = document.createElement("div");
        createMinLengthDiv.className = "form-group minlength-wrap";

        const createLabel = document.createElement("label");
        createLabel.innerHTML = "Min Length";

        const createInputWrapDiv = document.createElement("div");
        createInputWrapDiv.className = "input-wrap";

        const createInput = document.createElement("input");
        createInput.className = "form-control";
        createInput.name = "minlength";
        createInput.style.width = "75px";
        createInput.type = "number";
        createMinLengthDiv.appendChild(createLabel);
        createInputWrapDiv.appendChild(createInput);
        createMinLengthDiv.appendChild(createInputWrapDiv);

        let isMinLengthElementPushed = false;

        const formElementChildren = formElements[index].children;

        for (let j = 0; j < formElementChildren.length; j++) {
          if (formElementChildren[j].className == "form-group minlength-wrap") {
            isMinLengthElementPushed = true;
          }
        }
        if (!isMinLengthElementPushed) {
          formElements[index].appendChild(createMinLengthDiv);
        }
      }
    },
    onAddField: function (fieldId) {
      // if (isBtnDisabled) {
      //   setIsBtnDisabled(false);
      // }

      const splittedFieldId = fieldId.split("-");
      splittedFieldId[splittedFieldId.length - 1] = `${
        +splittedFieldId[splittedFieldId.length - 1] + 1
      }`;

      const finalFieldId = splittedFieldId.join("-");

      setTimeout(() => {
        const test = document.getElementById(finalFieldId);

        const liTag = document.getElementsByClassName("input-control ui-sortable-handle");

        for (let index = 0; index < liTag.length; index++) {
          // liTag[index].children[0].innerText.includes(test.innerText)
          if (
            test.innerText.includes(liTag[index].children[0].innerText) ||
            test.innerText.split("\n").includes(liTag[index].children[0].innerText)
          ) {
            liTag[index].style.pointerEvents = "none";
            liTag[index].style.opacity = "0.6";
          }
        }
        const removeBtn = document.getElementById("del_" + finalFieldId);

        removeBtn.addEventListener("click", (event) => {
          for (let index = 0; index < liTag.length; index++) {
            if (
              test.innerText.includes(liTag[index].children[0].innerText) ||
              test.innerText.split("\n").includes(liTag[index].children[0].innerText)
            ) {
              liTag[index].style.pointerEvents = "all";
              liTag[index].style.opacity = "1";
            }
          }

          // setTimeout(() => {
          //   const dragArea = document.getElementsByClassName(
          //     "frmb stage-wrap pull-right ui-sortable"
          //   );
          //   dragArea.item(0).dataset.content = DRAG_AREA_TEXT;
          // }, 290);
        });
      }, 100);
    },
    onCloseFieldEdit: function (editPanel) {
      const divOfInputField = document.getElementsByClassName("formbuilder-text form-group");
      const divOfTextAreaField = document.getElementsByClassName("formbuilder-textarea form-group");
      for (let index = 0; index < divOfInputField.length; index++) {
        divOfInputField[index].children[1].style.width = "100%";
      }

      for (let index = 0; index < divOfTextAreaField.length; index++) {
        divOfTextAreaField[index].children[1].style.width = "100%";
      }
    },
    disableFields: [
      "autocomplete",
      "button",
      "checkbox-group",
      "date",
      "file",
      "header",
      "hidden",
      "number",
      "paragraph",
      "radio-group",
      "select",
      "starRating",
      "text",
      "textarea",
    ],
  };

  const handleFormElementListing = async () => {
    const response = await getFormElements();
    if (response?.status === 200) {
      setFields(response.data.data);
    }
  };
  const handleGetAllServices = async () => {
    const response = await getServices();
    if (response?.status === 200) {
      const data = response.data.data.rows;
      const serviceData = [];
      data.map((service) => {
        const obj = {
          id: service["id"],
          label: service["name"],
        };
        serviceData.push(obj);
      });
      setServicesList(serviceData);
    }
  };

  useEffect(() => {
    handleFormElementListing();
    handleGetAllServices();
  }, []);

  useEffect(() => {
    if (fb.current) {
      setFormBuilder(
        $(fb.current).formBuilder({
          ...options,
          fields,
        })
      );

      // setTimeout(() => {
      //   const dragArea = document.getElementsByClassName(
      //     "frmb stage-wrap pull-right ui-sortable empty"
      //   );
      //   dragArea.item(0).dataset.content = DRAG_AREA_TEXT;
      // }, 100);
    }
  }, [fields]);

  async function saveData() {
    // THIS IF STATEMENT(AREA MARKED WITH ###) CAN BE USEFUL IF WE WILL NEED TO ADD SAVE CHANGES FEATURE. SOMETHING WHERE THE USER CAN SAVE THE CHANGES IN BETWEEN THE MAKING OF THE FORM

    // ###
    // if (form.length > 0) {
    //   const newParsedFormData = JSON.parse(formBuilder?.formData);

    //   const newData = form.map((previousElement, I) => {
    //     newParsedFormData.map((currentElement, J) => {
    //       if (I == J) {
    //         previousElement = {
    //           ...previousElement,
    //           ...currentElement,
    //         };
    //       }
    //     });
    //     return previousElement;
    //   });

    //   setForm(newData);
    // } else {
    // }
    // ###
    const minLength = document.getElementsByName("minlength");

    const parsedDataInstance = JSON.parse(formBuilder.formData);

    for (let index = 0; index < minLength.length; index++) {
      for (let j = 0; j < parsedDataInstance.length; j++) {
        if (index == j && parsedDataInstance[j].type !== "select") {
          parsedDataInstance[j] = {
            ...parsedDataInstance[j],
            minlength: +minLength[index].value,
          };
        }
      }
    }

    setForm(parsedDataInstance);

    // navigate("/");
  }

  function clearData() {
    formBuilder.actions.clearFields();
    setForm({});

    const liTag = document.getElementsByClassName("input-control ui-sortable-handle");
    for (let index = 0; index < liTag.length; index++) {
      liTag[index].style.pointerEvents = "all";
      liTag[index].style.opacity = "1";
    }

    // setTimeout(() => {
    //   const dragArea = document.getElementsByClassName(
    //     "frmb stage-wrap pull-right ui-sortable empty"
    //   );
    //   dragArea.item(0).dataset.content = DRAG_AREA_TEXT;
    // }, 100);
  }

  const handleForm = (form) => {
    const formElements = [];

    setTimeout(() => {
      const textType = document.getElementsByClassName(
        "formbuilder-icon-text input-control ui-sortable-handle"
      );
      const selectType = document.getElementsByClassName(
        "formbuilder-icon-select input-control ui-sortable-handle"
      );
      const textAreaType = document.getElementsByClassName(
        "formbuilder-icon-textarea input-control  ui-sortable-handle"
      );
      const radioGroupType = document.getElementsByClassName(
        "formbuilder-icon-radio-group input-control ui-sortable-handle"
      );
      const checkboxType = document.getElementsByClassName(
        "formbuilder-icon-checkbox input-control ui-sortable-handle"
      );

      for (let index = 0; index < textType.length; index++) {
        const obj = {
          label: textType[index].children[0].innerText,
          className: textType[index].className,
        };
        formElements.push(obj);
      }

      for (let index = 0; index < selectType.length; index++) {
        const obj = {
          label: selectType[index].children[0].innerText,
          className: selectType[index].className,
        };
        formElements.push(obj);
      }

      for (let index = 0; index < textAreaType.length; index++) {
        const obj = {
          label: textAreaType[index].children[0].innerText,
          className: textAreaType[index].className,
        };
        formElements.push(obj);
      }

      for (let index = 0; index < radioGroupType.length; index++) {
        const obj = {
          label: radioGroupType[index].children[0].innerText,
          className: radioGroupType[index].className,
        };
        formElements.push(obj);
      }

      for (let index = 0; index < checkboxType.length; index++) {
        const obj = {
          label: checkboxType[index].children[0].innerText,
          className: checkboxType[index].className,
        };
        formElements.push(obj);
      }

      for (let i = 0; i < form.length; i++) {
        for (let j = 0; j < formElements.length; j++) {
          if (form[i].label == formElements[j].label) {
            const domElement = document.getElementsByClassName(formElements[j].className);
            domElement[0].click();
          }
        }
      }

      const mainFormDiv = document.getElementsByClassName("text-field form-field");
      const mainTextAreaDiv = document.getElementsByClassName("textarea-field form-field");
      const mainSelectElementDiv = document.getElementsByClassName("select-field form-field");
      const radioElementDiv = document.getElementsByClassName("radio-group-field form-field");
      const checkboxElementDiv = document.getElementsByClassName("checkbox-field form-field");

      for (let index = 0; index < mainFormDiv.length; index++) {
        const firstChildLot = mainFormDiv[index].children;

        if (firstChildLot[1].className == "field-label") {
          for (let formEleIndex = 0; formEleIndex < form.length; formEleIndex++) {
            if (form[formEleIndex].label == firstChildLot[1].innerText) {
              const editFormElements = firstChildLot[5].children;

              for (
                let editFormEleIndex = 0;
                editFormEleIndex < editFormElements.length;
                editFormEleIndex++
              ) {
                const individualChildren = editFormElements[editFormEleIndex].children;
                for (
                  let individualChildrenIndex = 0;
                  individualChildrenIndex < individualChildren.length;
                  individualChildrenIndex++
                ) {
                  if (
                    individualChildren[individualChildrenIndex].innerText.toLowerCase() ==
                    "required"
                  ) {
                    if (form[formEleIndex]["required"]) {
                      firstChildLot[2].style.display = "inline";
                    }

                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["required"];
                  }

                  if (individualChildren[individualChildrenIndex].children[1]) {
                    if (
                      form[formEleIndex][
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name
                      ]
                    ) {
                      individualChildren[individualChildrenIndex].children[1].children[0].value =
                        form[formEleIndex][
                          individualChildren[individualChildrenIndex].children[1]?.children[0].name
                        ];

                      if (
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                        "description"
                      ) {
                        firstChildLot[3].style.display = "inline-block";
                        firstChildLot[3].setAttribute(
                          "tooltip",
                          form[formEleIndex][
                            individualChildren[individualChildrenIndex].children[1]?.children[0]
                              .name
                          ]
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      for (let index = 0; index < mainTextAreaDiv.length; index++) {
        const firstChildLot = mainTextAreaDiv[index].children;

        if (firstChildLot[1].className == "field-label") {
          for (let formEleIndex = 0; formEleIndex < form.length; formEleIndex++) {
            if (form[formEleIndex].label == firstChildLot[1].innerText) {
              const editFormElements = firstChildLot[5].children;

              for (
                let editFormEleIndex = 0;
                editFormEleIndex < editFormElements.length;
                editFormEleIndex++
              ) {
                const individualChildren = editFormElements[editFormEleIndex].children;
                for (
                  let individualChildrenIndex = 0;
                  individualChildrenIndex < individualChildren.length;
                  individualChildrenIndex++
                ) {
                  if (
                    individualChildren[individualChildrenIndex].innerText.toLowerCase() ==
                    "required"
                  ) {
                    if (form[formEleIndex]["required"]) {
                      firstChildLot[2].style.display = "inline";
                    }

                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["required"];
                  }

                  if (individualChildren[individualChildrenIndex].children[1]) {
                    if (
                      form[formEleIndex][
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name
                      ]
                    ) {
                      individualChildren[individualChildrenIndex].children[1].children[0].value =
                        form[formEleIndex][
                          individualChildren[individualChildrenIndex].children[1]?.children[0].name
                        ];

                      if (
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                        "description"
                      ) {
                        firstChildLot[3].style.display = "inline-block";
                        firstChildLot[3].setAttribute(
                          "tooltip",
                          form[formEleIndex][
                            individualChildren[individualChildrenIndex].children[1]?.children[0]
                              .name
                          ]
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      for (let index = 0; index < mainSelectElementDiv.length; index++) {
        const firstChildLot = mainSelectElementDiv[index].children;

        if (firstChildLot[1].className == "field-label") {
          for (let formEleIndex = 0; formEleIndex < form.length; formEleIndex++) {
            if (form[formEleIndex].label == firstChildLot[1].innerText) {
              const editFormElements = firstChildLot[5].children;

              for (
                let editFormEleIndex = 0;
                editFormEleIndex < editFormElements.length;
                editFormEleIndex++
              ) {
                const individualChildren = editFormElements[editFormEleIndex].children;
                for (
                  let individualChildrenIndex = 0;
                  individualChildrenIndex < individualChildren.length;
                  individualChildrenIndex++
                ) {
                  if (
                    individualChildren[individualChildrenIndex].innerText.toLowerCase() ==
                    "required"
                  ) {
                    if (form[formEleIndex]["required"]) {
                      firstChildLot[2].style.display = "inline";
                    }

                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["required"];
                  }

                  if (
                    individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                    "multiple"
                  ) {
                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["multiple"];
                  }

                  if (individualChildren[individualChildrenIndex].children[1]) {
                    if (
                      form[formEleIndex][
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name
                      ]
                    ) {
                      individualChildren[individualChildrenIndex].children[1].children[0].value =
                        form[formEleIndex][
                          individualChildren[individualChildrenIndex].children[1]?.children[0].name
                        ];

                      if (
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                        "description"
                      ) {
                        firstChildLot[3].style.display = "inline-block";
                        firstChildLot[3].setAttribute(
                          "tooltip",
                          form[formEleIndex][
                            individualChildren[individualChildrenIndex].children[1]?.children[0]
                              .name
                          ]
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      for (let index = 0; index < radioElementDiv.length; index++) {
        const firstChildLot = radioElementDiv[index].children;

        if (firstChildLot[1].className == "field-label") {
          for (let formEleIndex = 0; formEleIndex < form.length; formEleIndex++) {
            if (form[formEleIndex].label == firstChildLot[1].innerText) {
              const editFormElements = firstChildLot[5].children;

              for (
                let editFormEleIndex = 0;
                editFormEleIndex < editFormElements.length;
                editFormEleIndex++
              ) {
                const individualChildren = editFormElements[editFormEleIndex].children;
                for (
                  let individualChildrenIndex = 0;
                  individualChildrenIndex < individualChildren.length;
                  individualChildrenIndex++
                ) {
                  if (
                    individualChildren[individualChildrenIndex].innerText.toLowerCase() ==
                    "required"
                  ) {
                    if (form[formEleIndex]["required"]) {
                      firstChildLot[2].style.display = "inline";
                    }

                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["required"];
                  }

                  if (
                    individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                    "multiple"
                  ) {
                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["multiple"];
                  }

                  if (
                    individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                    "other"
                  ) {
                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["other"];
                  }

                  if (individualChildren[individualChildrenIndex].children[1]) {
                    if (
                      form[formEleIndex][
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name
                      ]
                    ) {
                      individualChildren[individualChildrenIndex].children[1].children[0].value =
                        form[formEleIndex][
                          individualChildren[individualChildrenIndex].children[1]?.children[0].name
                        ];

                      if (
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                        "description"
                      ) {
                        firstChildLot[3].style.display = "inline-block";
                        firstChildLot[3].setAttribute(
                          "tooltip",
                          form[formEleIndex][
                            individualChildren[individualChildrenIndex].children[1]?.children[0]
                              .name
                          ]
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      for (let index = 0; index < checkboxElementDiv.length; index++) {
        const firstChildLot = checkboxElementDiv[index].children;

        if (firstChildLot[1].className == "field-label") {
          for (let formEleIndex = 0; formEleIndex < form.length; formEleIndex++) {
            if (form[formEleIndex].label == firstChildLot[1].innerText) {
              const editFormElements = firstChildLot[5].children;

              for (
                let editFormEleIndex = 0;
                editFormEleIndex < editFormElements.length;
                editFormEleIndex++
              ) {
                const individualChildren = editFormElements[editFormEleIndex].children;
                for (
                  let individualChildrenIndex = 0;
                  individualChildrenIndex < individualChildren.length;
                  individualChildrenIndex++
                ) {
                  if (
                    individualChildren[individualChildrenIndex].innerText.toLowerCase() ==
                    "required"
                  ) {
                    if (form[formEleIndex]["required"]) {
                      firstChildLot[2].style.display = "inline";
                    }

                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["required"];
                  }

                  if (
                    individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                    "multiple"
                  ) {
                    individualChildren[individualChildrenIndex].children[1].children[0].checked =
                      form[formEleIndex]["multiple"];
                  }

                  if (individualChildren[individualChildrenIndex].children[1]) {
                    if (
                      form[formEleIndex][
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name
                      ]
                    ) {
                      individualChildren[individualChildrenIndex].children[1].children[0].value =
                        form[formEleIndex][
                          individualChildren[individualChildrenIndex].children[1]?.children[0].name
                        ];

                      if (
                        individualChildren[individualChildrenIndex].children[1]?.children[0].name ==
                        "description"
                      ) {
                        firstChildLot[3].style.display = "inline-block";
                        firstChildLot[3].setAttribute(
                          "tooltip",
                          form[formEleIndex][
                            individualChildren[individualChildrenIndex].children[1]?.children[0]
                              .name
                          ]
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }, 500);

    setIsBtnDisabled(false);
  };

  const initialValuesObj = {
    name: "",
    service: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(VALIDATION_MESSAGES.FORM_BUILDER.FORM_NAME_REQUIRED),
    service: Yup.number().required(VALIDATION_MESSAGES.FORM_BUILDER.SERVICE_REQUIRED),
  });

  const handleSubmit = async (values) => {
    try {
      formik.setSubmitting(true);

      if (!form.length) {
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: "Please select some fields to create a form",
          open: true,
        });
        return;
      }

      values = { ...values, form_id: idFromParams, form: form };

      const response = await updateForm(values);

      if (response && response.status === 200) {
        formik.setSubmitting(false);
        formik.resetForm();
        setNotification({
          color: "success",
          icon: "check",
          title: "Success",
          content: response.data.message,
          open: true,
        });
        navigate("/form-builder/form-listing");
      } else {
        formik.setSubmitting(false);
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: response.data.message,
          open: true,
        });
      }
    } catch (error) {
      formik.setSubmitting(false);
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: error.message || "Something went wrong!",
        open: true,
      });
    }
  };
  const formik = useFormik({
    initialValues: initialValuesObj,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const getSingleForm = async () => {
    try {
      const response = await getSingleFormData(idFromParams);
      if (response.status === 200) {
        formik.setValues({
          name: response.data.data.name,
          service: response.data.data.service_id,
        });

        handleForm(response.data.data.service_add_form_fields);
      } else {
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: response.data.message,
          open: true,
        });
      }
    } catch (error) {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: error.message || "Something went wrong!",
        open: true,
      });
    }
  };

  useEffect(() => {
    // const form = JSON.parse(localStorage.getItem("form"));
    // handleForm(form);

    getSingleForm();
  }, []);

  return (
    <>
      {fields?.length > 0 ? (
        <div className="main-container">
          <h5>Edit form</h5>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={3} mt="0.1rem">
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  value={formik.values.name}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                  label="Form Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  select
                  value={formik.values.service}
                  error={Boolean(formik.touched.service && formik.errors.service)}
                  helperText={formik.touched.service && formik.errors.service}
                  fullWidth
                  label="Service"
                  name="service"
                  onBlur={formik.handleBlur}
                  onChange={(event, value) => {
                    formik.setFieldValue(`service`, event.target.value);
                  }}
                  required
                >
                  {servicesList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <div ref={fb ?? ""} className="form-builder-area"></div>

            <div className="button-group">
              <Button variant="contained" type="submit" onClick={saveData}>
                Save Changes
              </Button>
              <Link to="/form-builder/form-listing">
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Link>
            </div>
          </form>
        </div>
      ) : (
        <div
          className="main-container"
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <CircularProgress color="success" />
        </div>
      )}
    </>
  );
};

export default FormEditor;
