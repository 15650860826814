// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Grid } from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRolePermissionsById } from "lib/Api/Roles";
import ViewEditForm from "components/Roles/viewEditForm";
import { Edit, Visibility } from "@mui/icons-material";
import { getModules } from "lib/Api/Modules";

function RoleDetails() {
  const { id } = useParams();

  const navigate = useNavigate();

  const { state } = useLocation();

  const [rolePermissions, setRolePermissions] = useState(null);
  const [isEdit, setIsEdit] = useState(!!state?.isEditMode);
  const [modules, setModules] = useState(null);

  const getModulesList = async () => {
    try {
      const response = await getModules();
      setModules(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getModulesList();
  }, []);

  const getRolePermissionDetails = async (roleId) => {
    try {
      const response = await getRolePermissionsById(roleId);
      if (response?.status === 200) {
        setRolePermissions(response.data.data);
      } else {
        navigate("/roles");
      }
    } catch (error) {
      console.log("Error (getRolePermissionDetails): ", error);
      navigate("/roles");
    }
  };

  useEffect(() => {
    getRolePermissionDetails(id);
  }, [id]);

  return (
    <CustomPageLayout>
      <MDBox pt={0} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  {rolePermissions?.name}
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can view and edit Roles and it's permissions.
                </MDTypography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={isEdit ? <Visibility /> : <Edit />}
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                >
                  {isEdit ? "View" : "Edit"}
                </Button>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
        <MDBox py={3} lineHeight={1}>
          {rolePermissions && modules && (
            <ViewEditForm rolePermissions={rolePermissions} modules={modules} isEditMode={isEdit} />
          )}
        </MDBox>
      </MDBox>
    </CustomPageLayout>
  );
}

export default RoleDetails;
