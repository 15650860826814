// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams, GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { getSubscriptionById } from "lib/Api/Subscriptions";
import { LoginFormContext } from "context/loginFormCtx";
import MDBadge from "components/MDBadge";
import { CURRENCY } from "utils/constants";
import { Circle, FiberManualRecord } from "@mui/icons-material";

function SubscriptionDetails() {
  const { id } = useParams();

  const navigate = useNavigate();

  const { setNotification } = useContext(LoginFormContext);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);

  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  const getSubscriptionDetails = async (subscriptionId) => {
    try {
      const response = await getSubscriptionById(subscriptionId);
      if (response?.status === 200) {
        const responseData = response?.data?.data;
        setUpcomingInvoice(responseData?.upcomingInvoice);
        setSubscriptionDetails(responseData);
      } else {
        console.log("response (getSubscriptionsData): ", response);
        setNotification({
          open: true,
          severity: "error",
          message: "Something went wrong while fetching the subscription details.",
        });
        // navigate("/subscriptions");
      }
    } catch (error) {
      console.log("Error (getSubscriptionDetails): ", error);
      setNotification({
        open: true,
        severity: "error",
        message: error?.message || "Something went wrong while fetching the subscription details.",
      });
      // navigate("/subscriptions");
    }
  };

  useEffect(() => {
    getSubscriptionDetails(id);
  }, [id]);

  return (
    <CustomPageLayout>
      {subscriptionDetails && (
        <MDBox py={3}>
          <Card>
            <Stack lineHeight={1} spacing={2} sx={{ p: 3 }}>
              <Grid container sx={{ pb: 3 }}>
                <Grid item>
                  <MDTypography variant="h5" fontWeight="medium">
                    {subscriptionDetails?.company_name || "Subscription Details"}
                  </MDTypography>
                  <MDTypography variant="button" color="text">
                    Subscription details and its related information.
                  </MDTypography>
                </Grid>
              </Grid>

              <Stack spacing={1}>
                <Stack spacing={1} direction="row" alignItems="flex-end">
                  <Stack sx={{ typography: "h4" }}>{subscriptionDetails?.customer?.name}</Stack>
                  <Stack sx={{ typography: "h6", color: "text.disabled" }}>on</Stack>
                  <Stack sx={{ typography: "h5" }}>
                    {subscriptionDetails?.plan?.product?.name || 'N/A'}
                  </Stack>
                  <Stack>
                    <MDBadge
                      badgeContent={subscriptionDetails?.status  || 'N/A'}
                      variant="contained"
                      container
                      color="success"
                    />
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={2} sx={{ typography: "body2" }}>
                  <Paper variant="outlined" sx={{ p: "20px", width: "100%" }}>
                    <Stack sx={{ typography: "body2" }}>
                      <Stack sx={{ color: "text.primary" }}>Started</Stack>
                      <Stack sx={{ color: "text.secondary" }}>
                        {subscriptionDetails?.current_period_start
                          ? moment(subscriptionDetails?.current_period_start * 1000).format(
                              "MM-DD-YYYY"
                            )
                          : "N/A"}
                      </Stack>
                    </Stack>
                  </Paper>

                  {/* <Stack>
                      <Divider orientation="vertical" />
                    </Stack> */}

                  {/* <Stack sx={{ typography: "body2" }}>
                      <Stack sx={{ color: "text.primary" }}>Next Invoice</Stack>
                      <Stack sx={{ color: "text.secondary" }}>US$ 10.50 on 29 Mar</Stack>
                    </Stack> */}
                </Stack>
              </Stack>

              <Stack>
                <Paper variant="outlined" sx={{ p: "20px" }}>
                  <Stack spacing={1}>
                    <Stack sx={{ typography: "h5" }}>Subscription Overview</Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                        <ListItem>
                          <ListItemText
                            primary="ID"
                            secondary={subscriptionDetails?.id || 'N/A'}
                            primaryTypographyProps={{ variant: "h6", color: "#566681" }}
                            secondaryTypographyProps={{
                              variant: "subtitle2",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                        <ListItem>
                          <ListItemText
                            primary="Customer"
                            secondary={subscriptionDetails?.customer?.name || "N/A"}
                            primaryTypographyProps={{ variant: "h6", color: "#566681" }}
                            secondaryTypographyProps={{
                              variant: "subtitle2",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                        <ListItem>
                          <ListItemText
                            primary="Email"
                            secondary={subscriptionDetails?.customer?.email || "N/A"}
                            primaryTypographyProps={{ variant: "h6", color: "#566681" }}
                            secondaryTypographyProps={{
                              variant: "subtitle2",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                        <ListItem>
                          <ListItemText
                            primary="Discount"
                            secondary={subscriptionDetails?.customer?.discount || "N/A"}
                            primaryTypographyProps={{ variant: "h6", color: "#566681" }}
                            secondaryTypographyProps={{
                              variant: "subtitle2",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                        <ListItem>
                          <ListItemText
                            primary="Created"
                            secondary={
                              subscriptionDetails?.current_period_start
                                ? moment(subscriptionDetails?.current_period_start * 1000).format(
                                    "MM-DD-YYYY, HH:mm"
                                  )
                                : "N/A"
                            }
                            primaryTypographyProps={{ variant: "h6", color: "#566681" }}
                            secondaryTypographyProps={{
                              variant: "subtitle2",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} md={3} sx={{ mt: 1 }}>
                        <ListItem>
                          <ListItemText
                            primary="Current"
                            secondary={
                              subscriptionDetails?.current_period_start &&
                              subscriptionDetails?.current_period_end
                                ? `${moment(
                                    subscriptionDetails?.current_period_start * 1000
                                  ).format("MM-DD-YYYY")} to ${moment(
                                    subscriptionDetails?.current_period_end * 1000
                                  ).format("MM-DD-YYYY")}`
                                : "N/A"
                            }
                            primaryTypographyProps={{ variant: "h6", color: "#566681"  }}
                            secondaryTypographyProps={{
                              variant: "subtitle2",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Stack>
                </Paper>
              </Stack>

              {/* <Stack>
                <Paper variant="outlined" sx={{ p: "20px" }}>
                  <Stack spacing={1}>
                    <Stack sx={{ typography: "h5" }}>Subscription Overview</Stack>

                    <Divider />

                    <TableBody
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{subscriptionDetails?.customer?.email}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Customer</TableCell>
                        <TableCell>{subscriptionDetails?.customer?.name}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Created</TableCell>
                        <TableCell>
                          {subscriptionDetails?.current_period_start
                            ? moment(subscriptionDetails?.current_period_start * 1000).format(
                                "MM-DD-YYYY, HH:mm"
                              )
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Current period</TableCell>
                        <TableCell>
                          {subscriptionDetails?.current_period_start &&
                          subscriptionDetails?.current_period_end
                            ? `${moment(subscriptionDetails?.current_period_start * 1000).format(
                                "MM-DD-YYYY"
                              )} to ${moment(subscriptionDetails?.current_period_end * 1000).format(
                                "MM-DD-YYYY"
                              )}`
                            : "-"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{subscriptionDetails?.id}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Stack>
                </Paper>
              </Stack> */}

              <Stack>
                <Paper variant="outlined" sx={{ p: "20px" }}>
                  <Stack spacing={1}>
                    <Stack sx={{ typography: "h5" }}>Pricing</Stack>

                    <Divider />

                    <Table>
                      <TableBody
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            typography: "h6",
                          },
                        }}
                      >
                        <TableCell>Product</TableCell>
                        <TableCell>Subscription Item ID</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Total</TableCell>
                      </TableBody>
                      {subscriptionDetails?.items?.data?.map((item, index) => {
                        return (
                          <TableBody key={index}>
                            <TableRow>
                              <TableCell>
                                <Stack>
                                  <Stack>
                                    <Stack spacing={1} direction="row" sx={{ fontWeight: 700 }}>
                                      <Stack>{subscriptionDetails?.plan?.product?.name}</Stack>
                                      <Stack>{"·"}</Stack>
                                      <Stack>{item?.plan?.id}</Stack>
                                    </Stack>
                                  </Stack>
                                  <Stack>{`${CURRENCY[item?.price?.currency] || "$"} ${(
                                    item?.price?.unit_amount / 100
                                  ).toFixed(2)} ${item?.price?.billing_scheme} / ${
                                    item?.price?.recurring?.interval
                                  }`}</Stack>
                                </Stack>
                              </TableCell>
                              <TableCell>{item?.id}</TableCell>
                              <TableCell>{item?.quantity ? item?.quantity : "---"}</TableCell>
                              <TableCell>
                                {item?.plan?.usage_type != "metered"
                                  ? `${CURRENCY[item?.price?.currency] || "$"} ${(
                                      item?.price?.unit_amount / 100
                                    ).toFixed(2)} / ${item?.price?.recurring?.interval}`
                                  : "Varies with usage"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                  </Stack>
                </Paper>
              </Stack>
              {/* Upcoming Invoice */}
              <Stack>
                <Paper variant="outlined" sx={{ p: "20px" }}>
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5">Upcoming Invoice</Typography>
                    </Stack>

                    <Stack spacing={1} sx={{ mt: 5 }}>
                      <Stack sx={{ typography: "h6" }}>Summary</Stack>

                      <Divider />

                      <TableBody
                        sx={{
                          [`& .${tableCellClasses.root}`]: {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell>Customer</TableCell>
                          <TableCell>
                            <Typography variant="body2" color="text.primary">
                              {upcomingInvoice?.customer_name}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Customer Email</TableCell>
                          <TableCell>{upcomingInvoice?.customer_email}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Stack>

                    <Stack spacing={1} sx={{ mt: 5 }}>
                      <Table>
                        <TableBody
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              typography: "h6",
                            },
                          }}
                        >
                          <TableCell sx={{ width: "70%" }}>Description</TableCell>
                          <TableCell>Qty</TableCell>
                          <TableCell>Unit Price</TableCell>
                          <TableCell>Amount</TableCell>
                        </TableBody>

                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Stack spacing={1} direction="row" sx={{ fontWeight: 700 }}>
                                <Stack>
                                  {upcomingInvoice?.lines?.data[0]?.plan?.nickname || "Plan"}
                                </Stack>
                                <Stack>{`(${moment(upcomingInvoice?.period_start * 1000).format(
                                  "DD MMM YYYY"
                                )} - ${moment(upcomingInvoice?.period_end * 1000).format(
                                  "DD MMM YYYY"
                                )})`}</Stack>
                              </Stack>
                            </TableCell>
                            <TableCell>{upcomingInvoice?.lines?.data[0]?.quantity}</TableCell>
                            <TableCell>
                              ${upcomingInvoice?.lines?.data[0]?.price?.unit_amount / 100}
                            </TableCell>
                            <TableCell>
                              $
                              {upcomingInvoice?.lines?.data[0]?.quantity *
                                (upcomingInvoice?.lines?.data[0]?.price?.unit_amount / 100)}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <Stack direction="column" rowGap={2}>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  Subtotal
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  Total excluding tax
                                </Typography>

                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  Total
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  Amount due
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="column" rowGap={2}>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  ${upcomingInvoice?.subtotal / 100 ?? "-"}
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  ${upcomingInvoice?.subtotal_excluding_tax / 100 ?? "-"}
                                </Typography>

                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  ${upcomingInvoice?.total / 100 ?? "-"}
                                </Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: "medium" }}>
                                  ${upcomingInvoice?.amount_due / 100 ?? "-"}
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
            </Stack>
          </Card>
        </MDBox>
      )}
    </CustomPageLayout>
  );
}

export default SubscriptionDetails;
