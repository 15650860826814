// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import { initialValues } from "./schemas/initialValue";
import { ValidationSchema } from "./schemas/validation";
import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { userProfileChangePassword } from "lib/Api/Authentication";
import { LoginFormContext } from "context/loginFormCtx";

function ChangePassword() {
  const { setNotification } = useContext(LoginFormContext);

  const passwordRequirements = [
    "Min 6 characters",
    "One special characters",
    "One uppercase letter",
    "One lowercase letter",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const handleSubmit = async (values) => {
    let response = await userProfileChangePassword(values);
    if (response?.status === 200) {
      setNotification({
        color: "success",
        icon: "check",
        title: "Success",
        content: response.data.message,
        open: true,
      });
      formik.resetForm();
    } else {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: response.data.message,
        open: true,
      });
    }
  };

  // Formik validation of user profile...
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox onSubmit={formik.handleSubmit} component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDInput
              type="password"
              value={formik.values.old_password}
              error={formik.touched.old_password && formik.errors.old_password ? true : false}
              label="Current Password"
              name="old_password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
            {formik.touched.old_password && formik.errors.old_password ? (
              <Box component="p" sx={{ color: "#F44335", fontSize: "0.75rem", fontWeight: "300" }}>
                {formik.errors.old_password}
              </Box>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <MDInput
              type="password"
              value={formik.values.password}
              error={formik.touched.password && formik.errors.password ? true : false}
              label="New Password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
            {formik.touched.password && formik.errors.password ? (
              <Box component="p" sx={{ color: "#F44335", fontSize: "0.75rem", fontWeight: "300" }}>
                {formik.errors.password}
              </Box>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <MDInput
              type="password"
              value={formik.values.confirm_password}
              error={
                formik.touched.confirm_password && formik.errors.confirm_password ? true : false
              }
              label="Confirm Password"
              name="confirm_password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
            />
            {formik.touched.confirm_password && formik.errors.confirm_password ? (
              <Box component="p" sx={{ color: "#F44335", fontSize: "0.75rem", fontWeight: "300" }}>
                {formik.errors.confirm_password}
              </Box>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox>
          <MDBox ml="auto" mt="30px" sx={{ display: "flex", justifyContent: "end" }}>
            <Button type="submit" size="small" className="personnel-submit_btn">
              update password
            </Button>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
