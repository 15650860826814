// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 150000;
  width: 100%;
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--primary-color);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=spinner.css.map */`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/spinner.scss","webpack://./src/components/LoadingSpinner/spinner.css"],"names":[],"mappings":"AA4BA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,gBAAA;AC3BJ;;AD8BA;EACI,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AC3BJ;;AD8BA;EACI,gEAAA;EACA,2BAAA;AC3BJ;;AD8BA;EACI,YAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,gCAAA;EACA,qBAAA;AC3BJ;;AD8BA;EACI,wBAAA;AC3BJ;;AD8BA;EACI,SAAA;EACA,UAAA;AC3BJ;;AD8BA;EACI,wBAAA;AC3BJ;;AD8BA;EACI,SAAA;EACA,UAAA;AC3BJ;;AD8BA;EACI,wBAAA;AC3BJ;;AD8BA;EACI,SAAA;EACA,UAAA;AC3BJ;;AD8BA;EACI,wBAAA;AC3BJ;;AD8BA;EACI,SAAA;EACA,UAAA;AC3BJ;;AD8BA;EACI,uBAAA;AC3BJ;;AD8BA;EACI,SAAA;EACA,UAAA;AC3BJ;;AD8BA;EACI,wBAAA;AC3BJ;;AD8BA;EACI,SAAA;EACA,UAAA;AC3BJ;;AD8BA;EACI,wBAAA;AC3BJ;;AD8BA;EACI,SAAA;EACA,UAAA;AC3BJ;;AD8BA;EACI,wBAAA;AC3BJ;;AD8BA;EACI,SAAA;EACA,UAAA;AC3BJ;;AD8BA;EACI;IACI,uBAAA;EC3BN;ED8BE;IACI,yBAAA;EC5BN;AACF,CAAA,sCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
