import { useContext } from "react";
import { useFormik } from "formik";

// react-router-dom components
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import AuthLayout from "../components/AuthLayout";

// Image
import ccLogo from "assets/cc-images/form-side-img.svg";
import { initialValues } from "./schemas/initialValue";
import validations from "./schemas/validation";
import { Box } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { LoginFormContext } from "context/loginFormCtx";
import { userLogin } from "lib/Api/Authentication";
import { CommonContext } from "context/CommonCtx";

function LogIn() {
  const { setNotification, setUserDetail, LogIn } = useContext(LoginFormContext);
  const { CCLoader } = useContext(CommonContext);

  const handleSubmit = async (values) => {
    CCLoader(true);
    const response = await userLogin(values);
    if (response?.status === 200) {
      setUserDetail(response.data.data);
      LogIn(response.data.data);
      setNotification({
        color: "success",
        icon: "check",
        title: "Success",
        content: response.data.message,
        open: true,
      });
      CCLoader(false);
    } else {
      CCLoader(false);
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: response.data.message,
        open: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validations,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthLayout
        title="Sign In"
        description="Enter your email and password to sign in"
        illustration={ccLogo}
      >
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              fullWidth
              values={formik.values.email}
              name="email"
              label="Email"
              error={formik.touched.email && formik.errors.email ? true : false}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <Box component="p" sx={{ color: "#F44335", fontSize: "0.75rem", fontWeight: "300" }}>
                {formik.errors.email}
              </Box>
            ) : (
              ""
            )}
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              name="password"
              onChange={formik.handleChange}
              values={formik.values.password}
              label="Password"
              fullWidth
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
            {formik.touched.password && formik.errors.password ? (
              <Box component="p" sx={{ color: "#F44335", fontSize: "0.75rem", fontWeight: "300" }}>
                {formik.errors.password}
              </Box>
            ) : (
              ""
            )}
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="primary" size="large" fullWidth>
              sign in
            </MDButton>
          </MDBox>
          <MDBox mt={3} textAlign="center">
            <MDTypography variant="button" color="text">
              <Link to="/forgot-password">
                <MDTypography
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                  Forgot Password
                  <KeyboardArrowRight sx={{ width: "18px" }} />
                </MDTypography>
              </Link>
            </MDTypography>
          </MDBox>
        </MDBox>
      </AuthLayout>
    </form>
  );
}

export default LogIn;

LogIn.prototype = {};
