import { Grid } from "@mui/material";
import FormBuilderComponent from "components/FormBuilder/FormBuilder";
import FormEditor from "components/FormBuilder/FormEditor";
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import React from "react";

const EditForm = () => {
  return (
    <CustomPageLayout>
      <FormEditor />
    </CustomPageLayout>
  );
};

export default EditForm;
