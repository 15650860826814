// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Data
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Divider } from "rsuite";
import { LoginFormContext } from "context/loginFormCtx";
import { MODULES } from "./formData";
import { VALIDATION_MESSAGES } from "utils/constants";
import { PERMISSIONS } from "utils/constants";
import { updateRolePermissions } from "lib/Api/Roles";
import MDTypography from "components/MDTypography";
import { checkAllAccess } from "utils/helpers";

function ViewEditForm({ rolePermissions, isEditMode, modules: defaultModules }) {
  const { id: roleId, name: roleName, modules } = rolePermissions;

  const { setNotification } = useContext(LoginFormContext);

  const navigate = useNavigate();

  const initialPermissions = defaultModules.map((module, index) => {
    const modulePermissions = modules.find((m) => m.id == module.id);

    const tempSubModules = module.sub_modules.map((subModule) => {
      const subModulePermissions = modules.find((m) => m.id === subModule.id);

      return {
        module_id: subModule.id,
        module_name: subModule.name,
        parent_id: module.id,
        read_access: subModulePermissions
          ? subModulePermissions.permissions[PERMISSIONS.READ_ACCESS]
          : false,
        write_access: subModulePermissions
          ? subModulePermissions.permissions[PERMISSIONS.WRITE_ACCESS]
          : false,
        delete_access: subModulePermissions
          ? subModulePermissions.permissions[PERMISSIONS.DELETE_ACCESS]
          : false,
      };
    });

    const temp = {
      id: index,
      title: module.name,
      all_read_access: false,
      all_write_access: false,
      all_delete_access: false,
      subModules: [
        {
          module_id: module.id,
          module_name: module.name,
          parent_id: module.parent_id,
          read_access: modulePermissions
            ? modulePermissions.permissions[PERMISSIONS.READ_ACCESS]
            : false,
          write_access: modulePermissions
            ? modulePermissions.permissions[PERMISSIONS.WRITE_ACCESS]
            : false,
          delete_access: modulePermissions
            ? modulePermissions.permissions[PERMISSIONS.DELETE_ACCESS]
            : false,
        },
        ...tempSubModules,
      ],
    };

    temp.all_read_access = checkAllAccess([temp], 0, PERMISSIONS.READ_ACCESS) === 1;
    temp.all_write_access = checkAllAccess([temp], 0, PERMISSIONS.WRITE_ACCESS) === 1;
    temp.all_delete_access = checkAllAccess([temp], 0, PERMISSIONS.DELETE_ACCESS) === 1;

    return temp;
  });

  const [permissions, setPermissions] = useState(initialPermissions);

  const handlePermissionChange = (parentModuleIdx, moduleIdx, rwdAccess, value, allChecked) => {
    const tempPermissions = [...permissions];

    if (allChecked === true) {
      tempPermissions[parentModuleIdx][`all_${rwdAccess}`] = value;

      if (
        (rwdAccess === PERMISSIONS.WRITE_ACCESS || rwdAccess === PERMISSIONS.DELETE_ACCESS) &&
        value === true
      ) {
        tempPermissions[parentModuleIdx][`all_${PERMISSIONS.READ_ACCESS}`] = value;
      }

      if (rwdAccess === PERMISSIONS.READ_ACCESS && value === false) {
        tempPermissions[parentModuleIdx][`all_${PERMISSIONS.WRITE_ACCESS}`] = value;
        tempPermissions[parentModuleIdx][`all_${PERMISSIONS.DELETE_ACCESS}`] = value;
      }

      tempPermissions[parentModuleIdx].subModules.map((subModule, index) => {
        subModule[rwdAccess] = value;

        // If any of the sub module has write or delete access then read access should be true
        if (
          (rwdAccess === PERMISSIONS.WRITE_ACCESS || rwdAccess === PERMISSIONS.DELETE_ACCESS) &&
          value === true
        ) {
          subModule[PERMISSIONS.READ_ACCESS] = value;
        }

        // If any of module dose not have read access then write and delete access should be false
        if (rwdAccess === PERMISSIONS.READ_ACCESS && value === false) {
          subModule[PERMISSIONS.WRITE_ACCESS] = value;
          subModule[PERMISSIONS.DELETE_ACCESS] = value;
        }
      });
    } else {
      tempPermissions[parentModuleIdx].subModules[moduleIdx][rwdAccess] = value;

      // If any of the sub module has write or delete access then read access should be true
      if (
        (rwdAccess === PERMISSIONS.WRITE_ACCESS || rwdAccess === PERMISSIONS.DELETE_ACCESS) &&
        value === true
      ) {
        tempPermissions[parentModuleIdx].subModules[moduleIdx][PERMISSIONS.READ_ACCESS] = value;
      }

      // If any of module dose not have read access then write and delete access should be false
      if (rwdAccess === PERMISSIONS.READ_ACCESS && value === false) {
        tempPermissions[parentModuleIdx].subModules[moduleIdx][PERMISSIONS.WRITE_ACCESS] = value;
        tempPermissions[parentModuleIdx].subModules[moduleIdx][PERMISSIONS.DELETE_ACCESS] = value;

        tempPermissions[parentModuleIdx][`all_${PERMISSIONS.WRITE_ACCESS}`] = value;
        tempPermissions[parentModuleIdx][`all_${PERMISSIONS.DELETE_ACCESS}`] = value;
      }

      // If parent modules read access is false then all sub modules read access should be false
      if (rwdAccess === PERMISSIONS.READ_ACCESS && value === false && moduleIdx === 0) {
        tempPermissions[parentModuleIdx].subModules.map((subModule, index) => {
          subModule[PERMISSIONS.READ_ACCESS] = false;
          subModule[PERMISSIONS.WRITE_ACCESS] = false;
          subModule[PERMISSIONS.DELETE_ACCESS] = false;
        });
      }

      // Give read access to parent module if any of the sub module has read access
      if (moduleIdx !== 0 && value === true) {
        tempPermissions[parentModuleIdx].subModules[0][PERMISSIONS.READ_ACCESS] = true;
      }

      // If value is false then all access should be false (Applicable for all read, write and delete access)
      if (value === false) {
        tempPermissions[parentModuleIdx][`all_${rwdAccess}`] = value;
      } else {
        if (rwdAccess === PERMISSIONS.READ_ACCESS) {
          const all = checkAllAccess(tempPermissions, parentModuleIdx, rwdAccess);
          tempPermissions[parentModuleIdx][`all_${rwdAccess}`] = all === 1;
        } else {
          const allR = checkAllAccess(tempPermissions, parentModuleIdx, PERMISSIONS.READ_ACCESS);
          tempPermissions[parentModuleIdx][`all_${PERMISSIONS.READ_ACCESS}`] = allR === 1;
          const all = checkAllAccess(tempPermissions, parentModuleIdx, rwdAccess);
          tempPermissions[parentModuleIdx][`all_${rwdAccess}`] = all === 1;
        }
      }
    }

    setPermissions(tempPermissions);
  };

  const formik = useFormik({
    initialValues: {
      id: roleId,
      name: roleName,
      permissions: initialPermissions,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(VALIDATION_MESSAGES.ROLES.NAME),
      permissions: Yup.array().required(VALIDATION_MESSAGES.ROLES.PERMISSIONS),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);

        let tempPermissions = [];
        values.permissions.map((module, index) => {
          tempPermissions.push(module.subModules);
        });

        const temp = {
          ...values,
          permissions: tempPermissions.flat(),
        };

        const response = await updateRolePermissions(temp);

        if (response && response.status === 200) {
          setSubmitting(false);
          resetForm();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
          navigate("/roles");
        } else {
          setSubmitting(false);
          setNotification({
            color: "error",
            icon: "cancel",
            title: "Error",
            content: response.data.message,
            open: true,
          });
        }
      } catch (error) {
        console.log("Error: ", error);
        setSubmitting(false);
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: error.message || "Something went wrong!",
          open: true,
        });
      }
    },
  });

  useEffect(() => {
    if (!isEditMode) {
      setPermissions(initialPermissions);
      formik.setFieldValue("name", roleName);
    }
  }, [isEditMode]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <Card
              sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                position: "sticky",
                top: "11.5%",
              }}
            >
              <MDBox display="flex" flexDirection="column" p={2} m={0} sx={{ listStyle: "none" }}>
                {permissions.map(({ id, title }, index) => {
                  const itemKey = `item-${index}`;

                  const href = title.toLowerCase().replace(" ", "-");

                  return (
                    <MDBox key={itemKey} component="li" pt={index === 0 ? 0 : 1}>
                      <MDTypography
                        component="a"
                        href={`#${href}`}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        sx={({
                          borders: { borderRadius },
                          functions: { pxToRem },
                          palette: { light },
                          transitions,
                        }) => ({
                          display: "flex",
                          alignItems: "center",
                          borderRadius: borderRadius.md,
                          padding: `${pxToRem(10)} ${pxToRem(16)}`,
                          transition: transitions.create("background-color", {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.shorter,
                          }),

                          "&:hover": {
                            backgroundColor: light.main,
                            color: "var(--cc-primary)",
                          },
                        })}
                      >
                        {title}
                      </MDTypography>
                    </MDBox>
                  );
                })}
              </MDBox>
            </Card>
          </Grid>

          <Grid item md={9} xs={12}>
            <Card
              sx={{
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                p: 2,
                mb: 2,
              }}
            >
              <Grid container>
                <Grid item md={4} xs={12}>
                  <InputLabel htmlFor="name" required sx={{ my: 1 }}>
                    Role Name
                  </InputLabel>
                  <TextField
                    disabled={!isEditMode}
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    required
                  />
                </Grid>
              </Grid>
            </Card>

            {permissions.map(
              (
                { id, title, all_read_access, all_write_access, all_delete_access, subModules },
                index
              ) => {
                const moduleSectionId = title.toLowerCase().replace(" ", "-");

                return (
                  <Card
                    id={moduleSectionId}
                    sx={{
                      borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
                      p: 2,
                      mb: 2,
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1">{title}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!isEditMode}
                                checked={all_read_access}
                                onChange={(e) => {
                                  handlePermissionChange(
                                    id,
                                    null,
                                    PERMISSIONS.READ_ACCESS,
                                    e.target.checked,
                                    true
                                  );
                                }}
                              />
                            }
                            label="Read"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!isEditMode}
                                checked={all_write_access}
                                onChange={(e) => {
                                  handlePermissionChange(
                                    id,
                                    null,
                                    PERMISSIONS.WRITE_ACCESS,
                                    e.target.checked,
                                    true
                                  );
                                }}
                              />
                            }
                            label="Write"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={!isEditMode}
                                checked={all_delete_access}
                                onChange={(e) => {
                                  handlePermissionChange(
                                    id,
                                    null,
                                    PERMISSIONS.DELETE_ACCESS,
                                    e.target.checked,
                                    true
                                  );
                                }}
                              />
                            }
                            label="Delete"
                          />
                        </Box>
                      </Box>
                      <Divider />

                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Module Name</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Read</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Write</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Delete</TableCell>
                          </TableRow>
                          {subModules?.map((item, index) => {
                            return (
                              <TableRow key={item.id}>
                                <TableCell>{item.module_name}</TableCell>
                                <TableCell>
                                  <Switch
                                    disabled={!isEditMode}
                                    checked={!!item.read_access}
                                    onChange={(e) => {
                                      handlePermissionChange(
                                        id,
                                        index,
                                        PERMISSIONS.READ_ACCESS,
                                        e.target.checked
                                      );
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Switch
                                    disabled={!isEditMode}
                                    checked={!!item.write_access}
                                    onChange={(e) => {
                                      handlePermissionChange(
                                        id,
                                        index,
                                        PERMISSIONS.WRITE_ACCESS,
                                        e.target.checked
                                      );
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Switch
                                    disabled={!isEditMode}
                                    checked={!!item.delete_access}
                                    onChange={(e) => {
                                      handlePermissionChange(
                                        id,
                                        index,
                                        PERMISSIONS.DELETE_ACCESS,
                                        e.target.checked
                                      );
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </Card>
                );
              }
            )}

            {isEditMode && (
              <MDBox sx={{ my: 2 }} display="flex" justifyContent="center" alignItems="center">
                <Button
                  disabled={formik.isSubmitting}
                  type="submit"
                  sx={{ m: 1 }}
                  variant="contained"
                >
                  Save
                </Button>

                <Link to="/roles">
                  <Button variant="outlined">Cancel</Button>
                </Link>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default ViewEditForm;
