import { callGetApi } from "../interceptor";

// Get Modules List API function
export async function getModules() {
  try {
    const response = await callGetApi({ url: "modules/list" });
    return response;
  } catch (error) {
    throw error;
  }
}
