/* eslint-disable react/prop-types */
import { getUserProfile } from "lib/Api/Authentication";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessByModuleName } from "routes";
import Swal from "sweetalert2";
import { LOCAL_STORAGE } from "utils/constants";

export const LoginFormContext = createContext();

export const LoginContext = ({ children }) => {
  // set user id for otp confirm password
  const [userId, setUserId] = useState();
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [notification, setNotification] = useState({
    color: "success",
    icon: "check",
    title: "CasePath",
    content: "Hello, Welcome to CasePath!",
    open: false,
  });

  // Convert Permission Value 1/0 in Boolean
  function convertPermissionsIntToBoolean(modules) {
    return modules.map((module) => {
      return {
        ...module,
        permissions: {
          ...module.permissions,
          read_access: Boolean(module.permissions.read_access),
          write_access: Boolean(module.permissions.write_access),
          delete_access: Boolean(module.permissions.delete_access),
        },
      };
    });
  }

  // AdminLoIn function
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("IssLoging") || false);

  const LogIn = (values) => {
    localStorage.setItem("user_data", JSON.stringify(values.data));
    localStorage.setItem("authorization", values.token);
    localStorage.setItem("client", JSON.stringify(values.data.case_cloud_client));
    localStorage.setItem("role", JSON.stringify(values.data.role));
    localStorage.setItem("modules", JSON.stringify(values.data.role.modules));
    setIsLoggedIn(true);
    localStorage.setItem("loginTime", Date.now());
    localStorage.setItem("IssLoging", true);
    setModules(convertPermissionsIntToBoolean(values.data.role.modules));
    navigate("/dashboard");
  };

  // logout function
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("loginTime");
    localStorage.removeItem("authorization");
    localStorage.removeItem("role");
    localStorage.removeItem("modules");
    localStorage.removeItem("client");
    localStorage.removeItem("user_data");
    localStorage.removeItem("IssLoging");
    localStorage.removeItem(LOCAL_STORAGE.FILTERS);
    localStorage.removeItem(LOCAL_STORAGE.SORT_BY);
    navigate("/login");
  };

  useEffect(() => {
    let modules = JSON.parse(localStorage.getItem("modules"));
    if (modules) setModules(convertPermissionsIntToBoolean(modules));
  }, []);

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("IssLoging"));
  }, []);

  const [access, setAccess] = useState({});

  const getModuleAccess = (moduleName) => {
    let access = getAccessByModuleName(modules, moduleName);
    if (access) setAccess(access.permissions);
    if (access && !access.permissions.read_access) {
      setNotification({
        color: "error",
        icon: "error",
        title: "Error",
        content: "You are not authorized to access.",
        open: true,
      });
      window.location.href = "/dashboard";
    }
  };

  // Sweet alert fot invalid email & password //
  const showMessageNotification = (msg) => {
    return Swal.fire({
      title: msg.title,
      text: msg.text,
      icon: msg.icon,
      showCancelButton: msg.showCancelButton,
      confirmButtonColor: msg.confirmButtonColor,
      cancelButtonColor: msg.cancelButtonColor,
      confirmButtonText: msg.confirmButtonText,
    });
  };

  const getUserProfileData = async () => {
    let response = await getUserProfile();
    if (response?.status === 200) {
      setUserDetail(response.data.data);
    }
  };
  const CcLoader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  return (
    <LoginFormContext.Provider
      value={{
        notification,
        setNotification,
        userDetail,
        setUserDetail,
        showMessageNotification,
        LogIn,
        getModuleAccess,
        access,
        setAccess,
        modules,
        setModules,
        userId,
        setUserId,
        getUserProfileData,
        isLoggedIn,
        setIsLoggedIn,
        handleLogout,
        isLoading,
        setIsLoading,
        CcLoader,
      }}
    >
      {children}
    </LoginFormContext.Provider>
  );
};
