import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

// Get Service Types List API function
export async function getServiceTypes(data) {
  try {
    const response = await callPostApi({ url: "service-types/list", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Service Types Name List API function
export async function getNamesOfServiceTypes() {
  try {
    const response = await callGetApi({ url: "service-types/servicetype-name-list" });
    return response;
  } catch (error) {
    throw error;
  }
}

// Add Role Permissions API function
export async function addServiceTypes(data) {
  try {
    const response = await callPostApi({ url: "service-types/add", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Role Permissions By Id API function
export async function getServiceTypesById(id) {
  try {
    const response = await callGetApi({ url: `service-types/service-type-byid/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Delete Role Permissions API function
export async function deleteServiceTypes(body) {
  try {
    const response = await callDeleteApi({ url: `service-types/delete`, body: body });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update Role Permissions API function
export async function updateServiceTypes(data) {
  try {
    const response = await callPutApi({ url: "service-types/update", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Service Types Request List API function
export async function getServiceTypeRequestsAPI(data) {
  try {
    const response = await callPostApi({
      url: "service-types/get-all-service-type-requests",
      body: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get progress status update API function
export async function updateProgressStatusAPI(id, data) {
  try {
    const response = await callPutApi({
      url: `service-types/update-progress-status/${id}`,
      body: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}
