import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../MDModal/mdmodal.scss";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";
import { useContext } from "react";
import Draggable from "react-draggable";
import { ModalContext } from "context/ModalCtx";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
};

const MDModal = (props) => {
  const { open, setOpen, handleOpen } = useContext(ModalContext);

  const handleClose = () => {
    setOpen(false);
  };

  const HandleOpenModal = () => {
    props.handleOpenForm ?? handleOpen();
  };

  return (
    <>
      {props.buttonText && props.hasButton !== false && (
        <Button onClick={() => HandleOpenModal()} variant="outlined" startIcon={props.buttonIcon}>
          {props.buttonText}
        </Button>
      )}
      <Modal
        open={props.openState ?? open}
        onClose={props.handleFormClose ?? handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ opacity: `${props.opacity}` }}
        // hideBackdrop={true}
        sx={{ width: props.width, height: props.height }}
      >
        <Box>
          <Draggable defaultPosition={props.defaultPosition || { x: -450, y: -400 }}>
            <Box sx={style} className={`box_modal draggable-cursor ${props?.formClassName}`}>
              <div className="cc_modal_head">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="modal-modal-title"
                >
                  {props.modalhead}
                </Typography>
                <span className="modal-close_btn">
                  <CloseIcon onClick={props.handleFormClose ?? handleClose} />
                </span>
              </div>
              <MDBox>
                {/* form... */}
                {props.children}
              </MDBox>
            </Box>
          </Draggable>
        </Box>
      </Modal>
    </>
  );
};

export default MDModal;
