import $ from "jquery"; //Load jquery
import React, { createRef, useContext, useEffect, useRef, useState } from "react"; //For react component
// import the react-json-view component
import ReactJson from "react-json-view";
import { FormBuilderContext } from "context/FormBuilderCtx";
import {
  Grid,
  Button,
  Icon,
  LinearProgress,
  CircularProgress,
  TextField,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import "../../style/scss/FormBuilder/index.css";
import { getFormElements } from "lib/Api/FormBuilder";
import { Link, useNavigate } from "react-router-dom";
import { DRAG_AREA_TEXT } from "utils/constants";
import { useFormik } from "formik";
import { VALIDATION_MESSAGES } from "utils/constants";
import { getServices } from "lib/Api/Services";
import { LoginFormContext } from "context/loginFormCtx";
import { createForm } from "lib/Api/FormBuilder";
import { NOT_TIME_FIELDS } from "utils/constants";
// import { Button } from "@mui/base";
window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
require("formBuilder"); // For FormBuilder

const FormBuilderComponent = () => {
  const fb = useRef();
  let [formBuilder, setFormBuilder] = useState(null);
  let [fields, setFields] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const { form, setForm } = useContext(FormBuilderContext);
  const { setNotification } = useContext(LoginFormContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  const navigate = useNavigate();
  // icon: "✉",

  // let fields = [
  //   {
  //     label: "First Name",
  //     type: "text",
  //     icon: "✉",
  //   },
  //   {
  //     label: "Last Name",
  //     type: "text",
  //     icon: "✉",
  //   },
  //   {
  //     label: "Email",
  //     type: "text",
  //     subtype: "email",
  //     icon: "✉",
  //   },
  //   {
  //     label: "Assignee",
  //     type: "select",
  //     subtype: "select",
  //     icon: "✉",
  //   },
  //   {
  //     label: "Contact",
  //     type: "select",
  //     subtype: "select",
  //     icon: "✉",
  //   },
  //   {
  //     label: "Description",
  //     type: "textarea",
  //     icon: "✉",
  //   },
  // ];
  let templates = {
    text: function (fieldData) {
      return {
        field: `<input
            type="text"
            placeholder="First Name"
            id="first_name"
            name="first_name"
          />`,
      };
    },
    // lastName: function (fieldData) {
    //   return {
    //     field: `<input
    //         type="text"
    //         placeholder="Last Name"
    //         id="last_name"
    //         name="last_name"
    //       />`,
    //   };
    // },
    email: function (fieldData) {
      return {
        field: `<input type="email" placeholder="Email" id="email" name="email" />`,
      };
    },
    // assignee: function (fieldData) {
    //   return {
    //     field: `<select name="assignee" id="assignee"></select>`,
    //   };
    // },
    // contact: function (fieldData) {
    //   return {
    //     field: `<select name="contact" id="contact"></select>`,
    //   };
    // },
    // description: function (fieldData) {
    //   return {
    //     field: `<input
    //         type="text"
    //         placeholder="Description"
    //         id="description"
    //         name="description"
    //       />`,
    //   };
    // },
  };
  var options = {
    controlPosition: "left",
    controlOrder: ["text", "select", "textarea"],
    disabledActionButtons: ["data", "save", "clear"],
    disabledFieldButtons: {
      autocomplete: ["copy"],
      button: ["copy"],
      "checkbox-group": ["copy"],
      date: ["copy"],
      file: ["copy"],
      header: ["copy"],
      hidden: ["copy"],
      number: ["copy"],
      paragraph: ["copy"],
      "radio-group": ["copy"],
      select: ["copy"],
      starRating: ["copy"],
      text: ["copy"],
      textarea: ["copy"],
      checkbox: ["copy"],
    },
    onOpenFieldEdit: async function (editPanel) {
      const placeholderWrap = document.getElementsByClassName("placeholder-wrap");

      const formElements = document.getElementsByClassName("form-elements");

      for (let index = 0; index < placeholderWrap.length; index++) {
        placeholderWrap[index].children[0].innerHTML = "Display Text";
      }

      for (let index = 0; index < formElements.length; index++) {
        const createMinLengthDiv = document.createElement("div");
        createMinLengthDiv.className = "form-group minlength-wrap";

        const createLabel = document.createElement("label");
        createLabel.innerHTML = "Min Length";

        const createInputWrapDiv = document.createElement("div");
        createInputWrapDiv.className = "input-wrap";

        const createInput = document.createElement("input");
        createInput.className = "form-control";
        createInput.name = "minlength";
        createInput.style.width = "75px";
        createInput.type = "number";

        createMinLengthDiv.appendChild(createLabel);
        createInputWrapDiv.appendChild(createInput);
        createMinLengthDiv.appendChild(createInputWrapDiv);

        let isMinLengthElementPushed = false;

        for (let j = 0; j < formElements[index].children.length; j++) {
          if (formElements[index].children[j].className == "form-group minlength-wrap") {
            isMinLengthElementPushed = true;
          }
        }
        if (!isMinLengthElementPushed) {
          formElements[index].appendChild(createMinLengthDiv);
        }
      }

      if (editPanel.children[0].children[1].children[1].innerText == "Email") {
        const emailChildren = editPanel.children[0].children;
        for (let index = 0; index < emailChildren.length; index++) {
          if (
            emailChildren[index].className.includes("maxlength-wrap") ||
            emailChildren[index].className.includes("minlength-wrap")
          ) {
            emailChildren[index].remove();
          }
        }
      } else if (editPanel.children[0].children[1].children[1].innerText == "Service") {
        const serviceChildren = editPanel.children[0].children;
        for (let index = 0; index < serviceChildren.length; index++) {
          if (serviceChildren[index].className.includes("other-wrap")) {
            serviceChildren[index].remove();
          }
        }
      }
    },
    onAddField: function (fieldId) {
      if (isBtnDisabled) {
        setIsBtnDisabled(false);
      }

      const splittedFieldId = fieldId.split("-");
      splittedFieldId[splittedFieldId.length - 1] = `${
        +splittedFieldId[splittedFieldId.length - 1] + 1
      }`;

      const finalFieldId = splittedFieldId.join("-");

      setTimeout(() => {
        const test = document.getElementById(finalFieldId);

        const liTag = document.getElementsByClassName("input-control ui-sortable-handle");

        for (let index = 0; index < liTag.length; index++) {
          if (
            liTag[index].children[0].innerText.includes(test.innerText) ||
            test.innerText.split("\n").includes(liTag[index].children[0].innerText)
          ) {
            liTag[index].style.pointerEvents = "none";
            liTag[index].style.opacity = "0.6";
          }
        }
        const removeBtn = document.getElementById("del_" + finalFieldId);

        removeBtn.addEventListener("click", (event) => {
          for (let index = 0; index < liTag.length; index++) {
            if (
              liTag[index].children[0].innerText.includes(test.innerText) ||
              test.innerText.split("\n").includes(liTag[index].children[0].innerText)
            ) {
              liTag[index].style.pointerEvents = "all";
              liTag[index].style.opacity = "1";
            }
          }

          setTimeout(() => {
            const dragArea = document.getElementsByClassName("frmb stage-wrap ui-sortable");
            dragArea.item(0).dataset.content = DRAG_AREA_TEXT;
          }, 500);
        });
      }, 100);
    },
    onCloseFieldEdit: function (editPanel) {
      const divOfInputField = document.getElementsByClassName("formbuilder-text form-group");
      const divOfTextAreaField = document.getElementsByClassName("formbuilder-textarea form-group");
      for (let index = 0; index < divOfInputField.length; index++) {
        divOfInputField[index].children[1].style.width = "100%";
      }

      for (let index = 0; index < divOfTextAreaField.length; index++) {
        divOfTextAreaField[index].children[1].style.width = "100%";
      }
    },
    disableFields: [
      "autocomplete",
      "button",
      "checkbox-group",
      "date",
      "file",
      "header",
      "hidden",
      "number",
      "paragraph",
      "radio-group",
      "select",
      "starRating",
      "text",
      "textarea",
    ],
  };

  const handleFormElementListing = async () => {
    const response = await getFormElements();
    if (response?.status === 200) {
      setFields(response.data.data);
    }
  };

  const handleGetAllServices = async () => {
    const response = await getServices();
    if (response?.status === 200) {
      const data = response.data.data.rows;
      const serviceData = [];
      data.map((service) => {
        const obj = {
          id: service["id"],
          label: service["name"],
        };
        serviceData.push(obj);
      });
      setServicesList(serviceData);
    }
  };

  useEffect(() => {
    handleFormElementListing();
    handleGetAllServices();
  }, []);

  useEffect(() => {
    if (fb.current) {
      setFormBuilder(
        $(fb.current).formBuilder({
          ...options,
          fields,
        })
      );

      setTimeout(() => {
        const dragArea = document.getElementsByClassName("frmb stage-wrap ui-sortable empty");
        dragArea.item(0).dataset.content = DRAG_AREA_TEXT;
      }, 100);
    }
  }, [fields]);

  async function saveData() {
    // THIS IF STATEMENT(AREA MARKED WITH ###) CAN BE USEFUL IF WE WILL NEED TO ADD SAVE CHANGES FEATURE. SOMETHING WHERE THE USER CAN SAVE THE CHANGES IN BETWEEN THE MAKING OF THE FORM

    // ###
    // if (form.length > 0) {
    //   const newParsedFormData = JSON.parse(formBuilder?.formData);

    //   const newData = form.map((previousElement, I) => {
    //     newParsedFormData.map((currentElement, J) => {
    //       if (I == J) {
    //         previousElement = {
    //           ...previousElement,
    //           ...currentElement,
    //         };
    //       }
    //     });
    //     return previousElement;
    //   });

    //   setForm(newData);
    // } else {
    // }
    // ###

    const minLength = document.getElementsByName("minlength");

    const parsedDataInstance = JSON.parse(formBuilder.formData);

    for (let index = 0; index < minLength.length; index++) {
      for (let j = 0; j < parsedDataInstance.length; j++) {
        if (index == j && parsedDataInstance[j].type !== "select") {
          parsedDataInstance[j] = {
            ...parsedDataInstance[j],
            minlength: +minLength[index].value,
          };
        }
      }
    }

    localStorage.setItem("form", JSON.stringify(parsedDataInstance));

    setForm(parsedDataInstance);

    // navigate("/");
  }

  function clearData() {
    formBuilder.actions.clearFields();
    setForm({});

    const liTag = document.getElementsByClassName("input-control ui-sortable-handle");
    for (let index = 0; index < liTag.length; index++) {
      liTag[index].style.pointerEvents = "all";
      liTag[index].style.opacity = "1";
    }

    setTimeout(() => {
      const dragArea = document.getElementsByClassName(
        "frmb stage-wrap pull-right ui-sortable empty"
      );
      dragArea.item(0).dataset.content = DRAG_AREA_TEXT;
    }, 100);
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      service: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(VALIDATION_MESSAGES.FORM_BUILDER.FORM_NAME_REQUIRED),
      service: Yup.number().required(VALIDATION_MESSAGES.FORM_BUILDER.SERVICE_REQUIRED),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);

        if (!form.length) {
          setNotification({
            color: "error",
            icon: "cancel",
            title: "Error",
            content: "Please select some fields to create a form",
            open: true,
          });
          return;
        }

        values = { ...values, form: form };

        const response = await createForm(values);

        if (response && response.status === 200) {
          setSubmitting(false);
          resetForm();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
          navigate("/form-builder");
        } else {
          setSubmitting(false);
          setNotification({
            color: "error",
            icon: "cancel",
            title: "Error",
            content: response.data.message,
            open: true,
          });
        }
      } catch (error) {
        console.log("Error: ", error);
        setSubmitting(false);
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: error.message || "Something went wrong!",
          open: true,
        });
      }
    },
  });

  return (
    <>
      {fields?.length > 0 ? (
        <div className="main-container">
          <h5>Form Builder</h5>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={3} mt="0.1rem">
              <Grid item md={4} xs={12}>
                <TextField
                  type="text"
                  value={formik.values.name}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                  label="Form Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  select
                  value={formik.values.service}
                  error={Boolean(formik.touched.service && formik.errors.service)}
                  helperText={formik.touched.service && formik.errors.service}
                  fullWidth
                  label="Service"
                  name="service"
                  onBlur={formik.handleBlur}
                  onChange={(event, value) => {
                    formik.setFieldValue(`service`, event.target.value);
                  }}
                  required
                >
                  {servicesList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <div ref={fb ?? ""} className="form-builder-area"></div>

            <div className="button-group">
              <Button
                variant="contained"
                type="submit"
                onClick={saveData}
                disabled={formik.isSubmitting}
              >
                Create Form
              </Button>
              <Link to="/form-builder">
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Link>
            </div>
          </form>
        </div>
      ) : (
        <div
          className="main-container"
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <CircularProgress color="success" />
        </div>
      )}
    </>
  );
};

export default FormBuilderComponent;
