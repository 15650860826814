import { useContext, useState } from "react";
import { useFormik } from "formik";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Authentication layout components
import AuthLayout from "layouts/authentication/components/AuthLayout";

// Image
import ccLogo from "assets/cc-images/form-side-img.svg";
import { ValidationSchema } from "./schemas/validation";
import { Box } from "@mui/material";
import { initialValues } from "./schemas/initialValue";
import { MuiOtpInput } from "mui-one-time-password-input";
import MDSnackbar from "components/MDSnackbar";
import { LoginFormContext } from "context/loginFormCtx";
import { otpVerificationApi } from "lib/Api/Authentication";

function OtpVerification() {
  const { setUserId, setNotification, notification } = useContext(LoginFormContext);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    let response = await otpVerificationApi(values);
    if (response?.status === 200) {
      navigate("/reset-password");
      setUserId(response.data.data.user_id);
      setNotification({
        color: "success",
        icon: "check",
        title: "Success",
        content: response.data.message,
        open: true,
      });
    } else {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: response.data.message,
        open: true,
      });
    }
  };

  const closeSuccessSB = () => setNotification({ ...notification, open: false });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
  });

  const [otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
    formik.setFieldValue("otp", newValue);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthLayout
        title="Otp Password Verification"
        description="Enter your 6 digits otp"
        illustration={ccLogo}
      >
        <MDBox>
          <MDBox mb={2}>
            <MuiOtpInput
              value={otp}
              onChange={handleChange}
              length={6}
              TextFieldsProps={{ type: "number" }}
            />

            {formik.touched.otp && formik.errors.otp ? (
              <Box component="p" sx={{ color: "#F44335", fontSize: "0.75rem", fontWeight: "300" }}>
                {formik.errors.otp}
              </Box>
            ) : (
              ""
            )}
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="primary" size="large" fullWidth>
              Submit
            </MDButton>
            {/* 
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography variant="button" color="primary" fontWeight="medium" textGradient>
                  <Link
                    to="/login"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <KeyboardArrowLeft sx={{ width: "18px" }} /> Back To Login
                  </Link>
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </AuthLayout>
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        dateTime=""
        open={notification.open}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    </form>
  );
}

export default OtpVerification;

OtpVerification.propTypes = {};
