// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Grid, IconButton } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect, useState } from "react";
// import { ModalContext } from "context/ModalCtx";

import { getPlans, deletePlan, updatePlan } from "lib/Api/Plans";

import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import DefaultCell from "examples/Tables/components/DefaultCell";
import { LoginFormContext } from "context/loginFormCtx";
import { STATUS_BUTTON_VALUE, DURATION_UNIT } from "utils/constants";
import { Delete, Edit } from "@mui/icons-material";

import moment from "moment";
import { TABLE_FILTERS } from "utils/constants";

import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";
import { PLAN_TYPES } from "utils/constants";
import { PLAN_DURATION_TYPE } from "utils/constants";
import { PLAN_TYPE_OPTIONS } from "utils/constants";
import { PLAN_DURATION_TYPE_OPTIONS } from "utils/constants";
import { deleteCoupon } from "lib/Api/Coupons";
import { COUPON_TYPE_OPTIONS } from "utils/constants";
import { COUPON_TYPES } from "utils/constants";
import { getCoupons } from "lib/Api/Coupons";
import { updateCoupon } from "lib/Api/Coupons";



function Coupons() {

  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const navigate = useNavigate();

  const handleDelete = async (id) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to delete this Coupon?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteCoupon(id);

        if (response?.status === 200) {
          getCouponData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const handleStatusChange = async (id, status) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to change the status of this Coupon?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateCoupon(id, { status: status ? 0 : 1 });

        if (response?.status === 200) {
          getCouponData();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const [dataTableColumns, setDataTableColumns] = useState([
    {
      Header: "Coupon Name",
      accessor: "coupon_name",
      filterInputType: "text",
      sort: true,
      Cell: ({ value, row }) => {
        return (
          <>
            <Link to={`/coupons/${row.values.id}`}>
              <DefaultCell value={value} />
            </Link>
          </>
        );
      },
    },
    {
      Header: "Coupon Type",
      accessor: "coupon_type",
      filterInputType: "select",
      sort: true,
      filterOptions: COUPON_TYPE_OPTIONS,
      Cell: ({ value, row }) => {
        return <DefaultCell value={COUPON_TYPES[value] || "N/A"} />;
      },
    },
    {
      Header: "Discount",
      accessor: "discount",
      filterInputType: "number",
      sort: true,
      Cell: ({ value, row }) => {
        return <DefaultCell value={value} />;
      },
    },
    {
      Header: "Created At",
      accessor: "created_at",
      filterInputType: "date",
      sort: true,
      Cell: ({ value }) => <DefaultCell value={moment(value).format("MM/DD/YYYY")} />,
    },
    {
      Header: "Updated At",
      accessor: "updated_at",
      filterInputType: "date",
      sort: true,
      Cell: ({ value }) => <DefaultCell value={moment(value).format("MM/DD/YYYY")} />,
    },
    {
      Header: "status",
      accessor: "status",
      filterInputType: "select",
      sort: false,
      filterOptions: [
        { label: "Inactive", value: 0 },
        { label: "Active", value: 1 },
      ],
      Cell: ({ value, row }) => (
        <MDButton
          color={STATUS_BUTTON_VALUE[value].color}
          sx={{
            background: `${STATUS_BUTTON_VALUE[value].background}`,
          }}
          size="small"
          circular={true}
          onClick={() => {
            handleStatusChange(row.original.id, value);
          }}
        >
          {STATUS_BUTTON_VALUE[value].label}
        </MDButton>
      ),
    },
    {
      Header: "Action",
      accessor: "actions",
      width: "3%",
      sort: false,
      Cell: ({ value, row }) => {
        return (
          <MDBox>
            {/* <IconButton
              onClick={() => {
                navigate(`/coupons/${row.original.id}/edit`);
              }}
            >
              <Edit />
            </IconButton> */}
            <IconButton
              onClick={() => {
                handleDelete(row.original.id);
              }}
            >
              <Delete />
            </IconButton>
          </MDBox>
        );
      },
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const [tableData, setTableData] = useState({
    columns: dataTableColumns,
    rows: [],
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(getFiltersFromCache(TABLE_FILTERS.COUPONS) || []);
  const [sortBy, setSortBy] = useState(getSortByFromCache(TABLE_FILTERS.COUPONS) || []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.COUPONS, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.COUPONS, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.COUPONS, sortObject);
  };

  const getCouponData = async () => {
    try {
      // setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (filter.id == "type"  || filter.id === "coupon_type" || filter.id == "status" ) {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getCoupons(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.rows,
        });
        setTotalPages(Math.ceil(response.data.data.count / pageSize));

        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error (getCouponsData): ", error);
    }
  };

  useEffect(() => {
    setTableData({
      ...tableData,
      columns: dataTableColumns,
    });
  }, [dataTableColumns]);

  useEffect(() => {
    getCouponData();
  }, [page, pageSize, filters, sortBy]);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Coupons
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the coupons.
                </MDTypography>
              </Grid>
              <Grid item>
                <Link to = "/coupons/add-coupons">
                  <Button variant="outlined" startIcon={<AddIcon />}>
                    Add New Coupon
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </MDBox>
          {!isLoading && (
            <DataTable
              isLoading={isLoading}
              table={tableData}
              pageSize={pageSize}
              currentPage={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              sorting={sortBy}
              handleSortByChange={handleSortByChange}
              getData={getCouponData}
              handleClearAllFilters={handleClearAllFilters}
              loading={isLoading}
            />
          )}
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default Coupons;
