import { VALIDATION_MESSAGES } from "utils/constants";
import * as Yup from "yup";

export const ValidationSchema = Yup.object({
  old_password: Yup.string().required(
    VALIDATION_MESSAGES.PROFILE.PASSWORD.CHANGE.REQUIRED_CURRENT_PASSWORD
  ),
  password: Yup.string()
    .required(VALIDATION_MESSAGES.PROFILE.PASSWORD.CHANGE.REQUIRED_NEW_PASSWORD)
    .min(6, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.MIN_LENGTH)
    .matches(/[0-9]/, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.NUMBER)
    .matches(/[a-z]/, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.LOWERCASE)
    .matches(/[A-Z]/, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.UPPERCASE)
    .matches(/[^\w]/, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.SPECIAL_CHARACTER),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID_CONFIRM_PASSWORD
    )
    .required(VALIDATION_MESSAGES.PROFILE.PASSWORD.CONFIRM_PASSWORD),
});
