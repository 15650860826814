/* eslint-disable react/prop-types */

import { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  // vehicle form open - close...
  const [open, setOpen] = useState(false);
  const [formIsEdit, setFormIsEdit] = useState(false);
  const [editData, setEditData] = useState({});

  const handleOpen = () => {
    setOpen(true);
    setFormIsEdit(false);
  };

  return (
    <ModalContext.Provider
      value={{
        open,
        setOpen,
        formIsEdit,
        setFormIsEdit,
        editData,
        setEditData,
        handleOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
