// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  background-color: white;
  border-radius: 12px;
}

.form-builder-area {
  margin: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
}

.button-group {
  display: flex;
  gap: 0.5rem;
}

.disabledMenuItem {
  pointer-events: none !important;
  opacity: 0.6;
}

.select-field .minlength-wrap {
  display: none !important;
}

.radio-group-field .minlength-wrap {
  display: none !important;
}

.checkbox-field .minlength-wrap {
  display: none !important;
}

.textarea-field .minlength-wrap {
  display: none !important;
}

.textarea-field .maxlength-wrap {
  display: none !important;
}


.form-elements .access-wrap,
.className-wrap,
.name-wrap,
.label-wrap,
.value-wrap,
.subtype-wrap,
.field-options,
.inline-wrap,
.toggle-wrap {
  display: none !important;
}

/* .form-wrap,
.form-builder,
.formbuilder-embedded-bootstrap,
.formbuilder-mobile {
  border: 1px solid yellow;
  display: flex;
  flex-direction: column-reverse;
}

.cb-wrap,
.pull-left {
  border: 2px solid green;
  width: 100% !important;
}

.frmb-control,
.ui-sortable {
  border: 1px solid red;
  display: flex;
  width: 100% !important;
}

.frmb,
.stage-wrap,
.pull-right,
.ui-sortable {
  width: 100% !important;
  height: 100% !important;
} */
`, "",{"version":3,"sources":["webpack://./src/style/scss/FormBuilder/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;;AAGA;;;;;;;;;EASE,wBAAwB;AAC1B;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA4BG","sourcesContent":[".main-container {\n  display: flex;\n  flex-direction: column;\n  padding: 1.2rem;\n  background-color: white;\n  border-radius: 12px;\n}\n\n.form-builder-area {\n  margin: 1rem 0 1rem 0;\n  display: flex;\n  flex-direction: column;\n  max-height: 500px;\n  overflow-y: auto;\n}\n\n.button-group {\n  display: flex;\n  gap: 0.5rem;\n}\n\n.disabledMenuItem {\n  pointer-events: none !important;\n  opacity: 0.6;\n}\n\n.select-field .minlength-wrap {\n  display: none !important;\n}\n\n.radio-group-field .minlength-wrap {\n  display: none !important;\n}\n\n.checkbox-field .minlength-wrap {\n  display: none !important;\n}\n\n.textarea-field .minlength-wrap {\n  display: none !important;\n}\n\n.textarea-field .maxlength-wrap {\n  display: none !important;\n}\n\n\n.form-elements .access-wrap,\n.className-wrap,\n.name-wrap,\n.label-wrap,\n.value-wrap,\n.subtype-wrap,\n.field-options,\n.inline-wrap,\n.toggle-wrap {\n  display: none !important;\n}\n\n/* .form-wrap,\n.form-builder,\n.formbuilder-embedded-bootstrap,\n.formbuilder-mobile {\n  border: 1px solid yellow;\n  display: flex;\n  flex-direction: column-reverse;\n}\n\n.cb-wrap,\n.pull-left {\n  border: 2px solid green;\n  width: 100% !important;\n}\n\n.frmb-control,\n.ui-sortable {\n  border: 1px solid red;\n  display: flex;\n  width: 100% !important;\n}\n\n.frmb,\n.stage-wrap,\n.pull-right,\n.ui-sortable {\n  width: 100% !important;\n  height: 100% !important;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
