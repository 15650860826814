// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Grid, IconButton } from "@mui/material";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import DefaultCell from "examples/Tables/components/DefaultCell";
import { Edit } from "@mui/icons-material";

import { TABLE_FILTERS } from "utils/constants";

import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";
import { getEmailTemplates } from "lib/Api/EmailTemplates";

function EmailTemplates() {
  const navigate = useNavigate();

  const [dataTableColumns, setDataTableColumns] = useState([
    {
      Header: "Template Name",
      accessor: "template_name",
      filterInputType: "text",
      sort: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Template Label",
      accessor: "template_label",
      filterInputType: "text",
      sort: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Action",
      width: "3%",
      sort: false,
      Cell: ({ value, row }) => {
        return (
          <MDBox>
            <IconButton
              onClick={() => {
                navigate(`/email-template/${row.original.id}/edit`);
              }}
            >
              <Edit />
            </IconButton>
          </MDBox>
        );
      },
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const [tableData, setTableData] = useState({
    columns: dataTableColumns,
    rows: [],
    count: 0,
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(getFiltersFromCache(TABLE_FILTERS.EMAIL_TEMPLATES) || []);
  const [sortBy, setSortBy] = useState(getSortByFromCache(TABLE_FILTERS.EMAIL_TEMPLATES) || []);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.EMAIL_TEMPLATES, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.EMAIL_TEMPLATES, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.EMAIL_TEMPLATES, sortObject);
  };

  const getEmailTemplatesData = async () => {
    try {
      // setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (filter.id == "status" || filter.id == "service_type_id") {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getEmailTemplates(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.rows,
          count: response.data.data.count,
        });
        setTotalPages(Math.ceil(response.data.data.count / pageSize));

        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error (getEmailTemplatesData): ", error);
    }
  };

  const handleLoad = () => {
    setIsLoading(true);

    if (tableData.rows.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTableData({
      ...tableData,
      columns: dataTableColumns,
    });
  }, [dataTableColumns]);

  useEffect(() => {
    getEmailTemplatesData();
  }, [page, pageSize, filters, sortBy]);

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Email Templates
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the email templates.
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          {!isLoading && (
            <DataTable
              isLoading={isLoading}
              table={tableData}
              pageSize={pageSize}
              currentPage={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              sorting={sortBy}
              handleSortByChange={handleSortByChange}
              getData={getEmailTemplatesData}
              handleClearAllFilters={handleClearAllFilters}
              loading={isLoading}
            />
          )}
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default EmailTemplates;
