export const MODULES = [
  {
    id: 1,
    name: "Case",
  },
  {
    id: 2,
    name: "Case Children",
  },
  {
    id: 3,
    name: "Case Relations",
  },
  {
    id: 4,
    name: "Services",
  },
  {
    id: 5,
    name: "Case Appointments",
  },
  {
    id: 6,
    name: "Case Transports",
  },
  {
    id: 7,
    name: "Reports",
  },
  {
    id: 8,
    name: "Personnel",
  },
  {
    id: 9,
    name: "Dashboard",
  },
  {
    id: 10,
    name: "Facesheets",
  },
  {
    id: 11,
    name: "Management Reports",
  },
  {
    id: 12,
    name: "Case Contacts",
  },
  {
    id: 13,
    name: "Vehicles",
  },
  {
    id: 14,
    name: "Case Service",
  },
  {
    id: 15,
    name: "Case Upload Files",
  },
  {
    id: 16,
    name: "Case Important Dates",
  },
  {
    id: 17,
    name: "Case Important Details",
  },
  {
    id: 18,
    name: "Case Status History",
  },
  {
    id: 19,
    name: "Case Progress Notes",
  },
  {
    id: 20,
    name: "Case Export To Excel",
  },
  {
    id: 21,
    name: "Case Print Visible Cases",
  },
  {
    id: 22,
    name: "RE Vehicle Transport",
  },
  {
    id: 23,
    name: "Personal Vehicle Transport",
  },
];
