// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ArrowDownward,
  ArrowUpward,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Filter } from "./filters";

function DataTableHeadCell({
  width,
  children,
  sorted,
  align,
  inputType,
  inputOptions,
  column,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let inputComponent = null;

  switch (inputType) {
    case "text":
      inputComponent = (
        <TextField
          type="text"
          size="small"
          variant="outlined"
          placeholder={`Filter by ${children}`}
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                onClick={() => {
                  console.log("rest.accessor 333.0: ", id);
                  handleFiltersChange(id, "");
                }}
              >
                <Close />
              </IconButton>
            ),
          }}
        />
      );
      break;
    case "number":
      inputComponent = (
        <TextField
          type="number"
          size="small"
          variant="outlined"
          placeholder={`Filter by ${children}`}
        />
      );
      break;
    case "select":
      inputComponent = (
        <Autocomplete
          options={inputOptions}
          placeholder={`Filter by ${children}`}
          size="small"
          sx={{ width: "auto", minWidth: 150 }}
          renderInput={(params) => <TextField {...params} label="" />}
        />
      );
      break;
    case "date":
      inputComponent = (
        <DatePicker
          sx={{ width: 200 }}
          format="YYYY-MM-DD"
          inputRef={{ size: "small" }}
          slotProps={{
            textField: {
              size: "small",
              clearable: true,
              onClear: (event) => {},
            },
          }}
        />
      );
      break;
    default:
      // inputComponent = (
      //   <>
      //     <Box sx={{ mb: 4.5}}></Box>
      //   </>
      // );
  }

  return (
    <MDBox
      component="th"
      width={width}
      py={inputComponent && 1.5}
      px={3}
      sx={({ palette: { light }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        {...rest}
        position="relative"
        textAlign={align}
        color={darkMode ? "white" : "secondary"}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold } }) => ({
          fontSize: size.xs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
        })}
      >
        {children}
        {sorted && (
          <MDBox
            position="absolute"
            top={0}
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            <MDBox position="absolute" top={-6} opacity={sorted === "asce" ? 1 : 0.8}>
              <KeyboardArrowUp
                color={sorted === "asce" ? "primary" : "text"}
                sx={{
                  width: "18px",
                  paddingBottom: "2px",
                  fontWeight: "700",
                }}
              />
            </MDBox>
            <MDBox position="absolute" top={0} opacity={sorted === "desc" ? 1 : 0.8}>
              <KeyboardArrowDown
                color={sorted === "desc" ? "primary" : "text"}
                sx={{
                  width: "18px",
                  paddingTop: "2px",
                  fontWeight: "700",
                }}
              />
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      {/* <MDBox>{inputComponent}</MDBox> */}
      <Filter column={column} />
    </MDBox>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
