import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

// Get Email Templates list API function
export async function getEmailTemplates(data) {
  try {
    const response = await callPostApi({ url: "email-template/list", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Email Templates By Id API function
export async function getEmailTemplatesById(id) {
  try {
    const response = await callGetApi({ url: `email-template/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}


// update Email Templates API function
export async function updateEmailTemplate(id, data) {
  try {
    const response = await callPutApi({ url: `email-template/update/${id}`, body: data });
    return response;
  } catch (error) {
    throw error;
  }
}