import { callPostApi, callPutApi } from "../interceptor";

// Get master settings List API function
export async function fetchMasterSettingsApi() {
  try {
    const response = await callPostApi({ url: "master-settings/list" });
    return response;
  } catch (error) {
    throw error;
  }
}

// update master settings API function
export async function updateMasterSettingsApi(id, data) {
  try {
    const response = await callPutApi({ url: `master-settings/update/${id}`, body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
