import { VALIDATION_MESSAGES } from "utils/constants";
import * as Yup from "yup";

export const ValidationSchema = Yup.object({
  new_password: Yup.string().required(VALIDATION_MESSAGES.PROFILE.PASSWORD.PASSWORD),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("new_password"), null],
      VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID_CONFIRM_PASSWORD
    )
    .required(VALIDATION_MESSAGES.PROFILE.PASSWORD.CONFIRM_PASSWORD),

  new_password: Yup.string()
    .required(VALIDATION_MESSAGES.PROFILE.PASSWORD.NEW_PASSWORD)
    .min(6, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.MIN_LENGTH)
    .matches(/[0-9]/, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.NUMBER)
    .matches(/[a-z]/, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.LOWERCASE)
    .matches(/[A-Z]/, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.UPPERCASE)
    .matches(/[^\w]/, VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID.SPECIAL_CHARACTER),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("new_password"), null],
      VALIDATION_MESSAGES.PROFILE.PASSWORD.INVALID_CONFIRM_NEW_PASSWORD
    )
    .required(VALIDATION_MESSAGES.PROFILE.PASSWORD.CONFIRM_PASSWORD),
});
