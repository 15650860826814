import { createContext, useState } from "react";

export const FormBuilderContext = createContext("");

export const FormBuilderContextProvider = ({ children }) => {
  const [form, setForm] = useState([]);

  return (
    <FormBuilderContext.Provider value={{ form, setForm }}>{children}</FormBuilderContext.Provider>
  );
};
