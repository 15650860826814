// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Grid } from "@mui/material";

import AddRolesForm from "components/Roles/addForm";
import { useEffect, useState } from "react";
import { getModules } from "lib/Api/Modules";

function AddRoles() {
  const [modules, setModules] = useState(null);

  const getModulesList = async () => {
    try {
      const response = await getModules();
      setModules(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getModulesList();
  }, []);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Add Role
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can add Roles and it's permissions.
                </MDTypography>
              </Grid>
              {/* <Grid item>
                <Link to="/roles">
                  <Button variant="outlined" startIcon={<ArrowBack />} onClick={() => {}}>
                    back
                  </Button>
                </Link>
              </Grid> */}
            </Grid>
          </MDBox>
        </Card>
        <MDBox py={3} lineHeight={1}>
          {modules && <AddRolesForm modules={modules} />}
        </MDBox>
      </MDBox>
    </CustomPageLayout>
  );
}

export default AddRoles;
