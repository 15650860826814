/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "layouts/dashboards/analytics/components/SalesByCountry";

// Data
import reportsBarChartData from "layouts/dashboards/analytics/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboards/analytics/data/reportsLineChartData";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";
import booking2 from "assets/images/products/product-2-min.jpg";
import booking3 from "assets/images/products/product-3-min.jpg";
import { Card, CardHeader, Typography } from "@mui/material";
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";
import { Bar } from "react-chartjs-2";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { getDashboardDataApi } from "lib/Api/Dashboard";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import moment from "moment";
import { PREDEFINED_RANGES } from "./helper";
import SubscriptionListing from "./SubscriptionListing";

const GRAPH_TYPE = {
  TENANT: "tenant",
  PAYMENT: "payment",
};

function Analytics() {
  const { sales, tasks } = reportsLineChartData;

  const [totalTenantData, setTotalTenantData] = useState(null);
  const [tenantGraphData, setTenantGraphData] = useState(null);
  const [paymentGraphData, setPaymentGraphData] = useState(null);

  const [tenantGraphDates, setTenantGraphDates] = useState({
    startDate: moment().startOf("week").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").format("YYYY-MM-DD"),
  });

  const [paymentGraphDates, setPaymentGraphDates] = useState({
    startDate: moment().startOf("week").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").format("YYYY-MM-DD"),
  });

  const handleDateChange = (graphType, value) => {
    if (graphType === GRAPH_TYPE.PAYMENT) {
      if (!value) {
        setPaymentGraphDates?.({
          startDate: moment().startOf("week").format("YYYY-MM-DD"),
          endDate: moment().endOf("week").format("YYYY-MM-DD"),
        });
      } else {
        const startDate = moment(value[0]).format("YYYY-MM-DD");
        const endDate = moment(value[1]).format("YYYY-MM-DD");
        setPaymentGraphDates?.({ startDate, endDate });
      }
    } else {
      if (!value) {
        setTenantGraphDates?.({
          startDate: moment().startOf("week").format("YYYY-MM-DD"),
          endDate: moment().endOf("week").format("YYYY-MM-DD"),
        });
      } else {
        const startDate = moment(value[0]).format("YYYY-MM-DD");
        const endDate = moment(value[1]).format("YYYY-MM-DD");
        setTenantGraphDates?.({ startDate, endDate });
      }
    }
  };

  // fetch dashboard data
  const fetchDashboardData = async () => {
    const response = await getDashboardDataApi({
      tenant: {
        startDate: tenantGraphDates?.startDate,
        endDate: tenantGraphDates?.endDate,
      },
      earnings: {
        startDate: paymentGraphDates?.startDate,
        endDate: paymentGraphDates?.endDate,
      },
    });

    if (response?.status === 200) {
      const data = response?.data?.data;
      setTotalTenantData(data.totalTenant);

      const tenantGraphOptions = {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: data.tenantGraphData.categories,
        },
        dataLabels: {
          enabled: false,
        },
      };

      const tenantGraphSeries = [
        {
          name: "tenant",
          data: data.tenantGraphData.graphData,
        },
      ];

      setTenantGraphData({ options: tenantGraphOptions, series: tenantGraphSeries });

      const paymentGraphOptions = {
        chart: {
          id: "basic-line",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: data.paymentGraphData.categories,
        },
        dataLabels: {
          enabled: false,
        },
      };

      const paymentGraphSeries = [
        {
          name: "payment",
          data: data.paymentGraphData.graphData,
        },
      ];

      setPaymentGraphData({ options: paymentGraphOptions, series: paymentGraphSeries });
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [tenantGraphDates, paymentGraphDates]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Typography variant="h3" fontWeight="medium" textAlign="center">
          Welcome to CasePath admin panel!
        </Typography>
      </MDBox>
      <MDBox py={3}>
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Total Tenants"
                  count={totalTenantData}
                  percentage={{
                    color: "success",
                    amount: "+3%",
                    label: "than last month",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ p: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CardHeader title="Tenant" />
                  <DateRangePicker
                    appearance="default"
                    placeholder="Select Date Range"
                    defaultValue={[
                      new Date(tenantGraphDates.startDate),
                      new Date(tenantGraphDates.endDate),
                    ]}
                    style={{ width: 230, color: "inherit" }}
                    onChange={(value) => {
                      handleDateChange(GRAPH_TYPE.TENANT, value);
                    }}
                    showOneCalendar
                    ranges={PREDEFINED_RANGES}
                  />
                </Stack>
                <Chart
                  options={tenantGraphData?.options ?? {}}
                  series={tenantGraphData?.series ?? []}
                  type="bar"
                />
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ p: 2 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CardHeader title="Payment" />

                  <DateRangePicker
                    appearance="default"
                    placeholder="Select Date Range"
                    defaultValue={[
                      new Date(paymentGraphDates.startDate),
                      new Date(paymentGraphDates.endDate),
                    ]}
                    style={{ width: 230, color: "inherit" }}
                    onChange={(value) => {
                      handleDateChange(GRAPH_TYPE.PAYMENT, value);
                    }}
                    showOneCalendar
                    ranges={PREDEFINED_RANGES}
                  />
                </Stack>
                <Chart
                  options={paymentGraphData?.options ?? {}}
                  series={paymentGraphData?.series ?? []}
                  type="line"
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 2 }}>
                <CardHeader title="Upcoming Subscriptions" />
                <SubscriptionListing />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
