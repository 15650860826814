// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Grid } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getServiceById } from "lib/Api/Services";
import { LoginFormContext } from "context/loginFormCtx";
import EditEmailTemplateForm from "components/EmailTemplates/EditTemplateForm";
import { getEmailTemplatesById } from "lib/Api/EmailTemplates";

function EditEmailTemplate() {
  const { id } = useParams();

  const [emailTemplateData, setEmailTemplateData] = useState(null);

  const navigate = useNavigate();

  const { setNotification } = useContext(LoginFormContext);

  const getEmailTemplateData = async () => {
    try {
      const response = await getEmailTemplatesById(id);

      if (response?.status === 200) {
        const resData = response?.data?.data
        resData['type'] = {
          id: resData['service_type']?.id ?? "",
          label: resData['service_type']?.name ?? ""
        } 
        setEmailTemplateData(response?.data?.data);
      } else {
        setNotification({
          color: "error",
          icon: "check",
          title: "Error",
          content: response?.data?.message,
          open: true,
        });
        navigate("/email-templates");
      }
    } catch (error) {
      setNotification({
        color: "error",
        icon: "check",
        title: "Error",
        content: error?.message || "Something went wrong!",
        open: true,
      });
    }
  };

  useEffect(() => {
    getEmailTemplateData();
  }, [id]);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Edit Email Template
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can edit email template.
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            {emailTemplateData && <EditEmailTemplateForm emailTemplateData={emailTemplateData} />}
          </MDBox>
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default EditEmailTemplate;
