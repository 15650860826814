import { VALIDATION_MESSAGES } from "utils/constants";
import * as Yup from "yup";

export const ValidationSchema = Yup.object({
  first_name: Yup.string().required(VALIDATION_MESSAGES.PROFILE.FIRST_NAME.REQUIRED),
  last_name: Yup.string().required(VALIDATION_MESSAGES.PROFILE.LAST_NAME.REQUIRED),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.PROFILE.EMAIL.INVALID)
    .required(VALIDATION_MESSAGES.PROFILE.EMAIL.REQUIRED),
});
