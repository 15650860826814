import { useContext } from "react";
import { useFormik } from "formik";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import AuthLayout from "layouts/authentication/components/AuthLayout";

// Image
import ccLogo from "assets/cc-images/form-side-img.svg";
import { ValidationSchema } from "./schemas/validation";
import { Box } from "@mui/material";
import { initialValues } from "./schemas/initialValue";
import MDSnackbar from "components/MDSnackbar";

import { KeyboardArrowLeft } from "@mui/icons-material";
import { LoginFormContext } from "context/loginFormCtx";
import { forgotPasswordOtpApi } from "lib/Api/Authentication";

function ForgotPassword() {
  const { notification, setNotification } = useContext(LoginFormContext);
  const closeSuccessSB = () => setNotification({ ...notification, open: false });

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const response = await forgotPasswordOtpApi(values);
    if (response?.status === 200) {
      navigate("/otp-verification");

      setNotification({
        color: "success",
        icon: "check",
        title: "Success",
        content: response.data.message,
        open: true,
      });
    } else {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: response.data.message,
        open: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthLayout
        title="Forgot Password"
        description="Enter your email to verify"
        illustration={ccLogo}
      >
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              values={formik.values.email}
              name="email"
              label="Email"
              fullWidth
              error={formik.touched.email && formik.errors.email ? true : false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <Box
                component="p"
                sx={{
                  color: "#F44335",
                  fontSize: "0.75rem",
                  fontWeight: "300",
                  marginTop: "3px",
                  marginLeft: "2px",
                }}
              >
                {formik.errors.email}
              </Box>
            ) : (
              ""
            )}
          </MDBox>

          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="primary" size="large" fullWidth>
              Submit
            </MDButton>

            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                <MDTypography variant="button" color="primary" fontWeight="medium" textGradient>
                  <Link
                    to="/login"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <KeyboardArrowLeft sx={{ width: "18px" }} /> Back To Login
                  </Link>
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </AuthLayout>
      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        dateTime=""
        open={notification.open}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    </form>
  );
}

export default ForgotPassword;

ForgotPassword.propTypes = {};
