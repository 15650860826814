// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/profile/components/Sidenav";
import Header from "layouts/pages/account/profile/components/Header";
import BasicInfo from "layouts/pages/account/profile/components/BasicInfo";
import ChangePassword from "layouts/pages/account/profile/components/ChangePassword";
// import Authentication from "layouts/pages/account/profile/components/Authentication";
// import Notifications from "layouts/pages/account/profile/components/Notifications";
// import DeleteAccount from "layouts/pages/account/profile/components/DeleteAccount";

function Profile() {
  return (
    <BaseLayout stickyNavbar>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Profile;
