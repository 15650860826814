import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import sidebarLogo from "assets/cc-images/sidebar_logo.png";
import miniSidebarLogo from "assets/cc-images/mini_sidebar_logo.png";
// import miniSidebarLogo from "assets/cc-images/mini_sidebar_logo.png";

import MDSnackbar from "components/MDSnackbar";
import { LoginFormContext } from "context/loginFormCtx";
import LoadingSpinner from "components/LoadingSpinner";
import { CommonContext } from "context/CommonCtx";

import "../src/style/scss/main.css";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  let authorization = localStorage.getItem("authorization")
    ? localStorage.getItem("authorization")
    : "";
  let forgotToken = localStorage.getItem("forgotToken") ? localStorage.getItem("forgotToken") : "";
  let navigate = useNavigate();
  let location = useLocation();
  var allowPath = ["/login", "/forgot-password", "/otp-verification", "/reset-password"];

  useEffect(() => {
    if (localStorage.getItem("authorization")) {
      if (allowPath.includes(location.pathname)) {
        return navigate("/dashboard");
      }
    } else {
      if (!allowPath.includes(location.pathname)) {
        navigate("/login");
      }
    }
  }, []);

  if (localStorage.getItem("authorization")) {
    if (allowPath.includes(location.pathname)) {
      navigate("/dashboard");
    }
  } else {
    if (!allowPath.includes(location.pathname)) {
      navigate("/login");
    }
  }

  const { notification, setNotification, isLoggedIn, setIsLoggedIn, handleLogout, isLoading } =
    useContext(LoginFormContext);

  const { CCLoader } = useContext(CommonContext);

  const closeSuccessSB = () => setNotification({ ...notification, open: false });

  let caseOverview = pathname?.split("/")?.[1];

  useEffect(() => {
    if (caseOverview !== "case-overview") {
      addPersonnelActivityData({
        case_id: null,
        family_id: null,
        page_name: pathname,
      });
    }
  }, [pathname]);

  const addPersonnelActivityData = async (data) => {
    // let response = await addPersonnelActivityReport(data);
  };

  let loginTime = localStorage.getItem("loginTime");

  useEffect(() => {
    if (isLoggedIn) {
      if (loginTime) {
        const twelveHoursInMillis = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
        // const twelveHoursInMillis = 60000;
        const currentTime = Date.now();
        const elapsedTime = currentTime - parseInt(loginTime);
        if (elapsedTime >= twelveHoursInMillis) {
          setIsLoggedIn(false);
          CCLoader(true);
          handleLogout();
          CCLoader(false);
        } else {
          const remainingTime = twelveHoursInMillis - elapsedTime;
          const logoutTimeout = setTimeout(() => {
            setIsLoggedIn(false);
            CCLoader(true);
            handleLogout();
            CCLoader(false);
          }, remainingTime);
          return () => clearTimeout(logoutTimeout);
        }
      }
    }
  }, [isLoggedIn]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={miniSidenav ? miniSidebarLogo : sidebarLogo}
            brandName=""
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator /> */}
        </>
      )}
      {/* {layout === "vr" && <Configurator />} */}

      {/* {isLoading && <LoadingSpinner />} */}

      <Routes>
        {getRoutes(routes)}
        {authorization && <Route path="*" element={<Navigate to="/dashboard" />} />}
      </Routes>

      <MDSnackbar
        color={notification.color}
        icon={notification.icon}
        title={notification.title}
        content={notification.content}
        dateTime=""
        open={notification.open}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
    </ThemeProvider>
  );
}
