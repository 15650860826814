// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Grid } from "@mui/material";
import AddEditPlanForm from "components/Plans/addEditForm";

function AddPlan() {
  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Add Plan
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can add new plan.
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            <AddEditPlanForm />
          </MDBox>
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default AddPlan;
