// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cc_textfield {
  width: 100%;
  text-transform: uppercase;
}

.modal-modal-title {
  font-size: 20px !important;
  margin-bottom: 25px !important;
}

.box_modal {
  border-radius: 12px;
  max-width: 952px;
  width: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 90%;
  height: auto;
}

.cc_modal_head {
  display: flex;
  align-items: flex-start !important;
  justify-content: space-between;
}

.cc_modal_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-close_btn {
  cursor: pointer;
}

.modal-close_btn:hover {
  color: #767676;
}`, "",{"version":3,"sources":["webpack://./src/components/MDModal/mdmodal.scss","webpack://./src/style/_mixins.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,yBAAA;AADJ;;AAIA;EACI,0BAAA;EACA,8BAAA;AADJ;;AAIA;EACI,mBAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;AADJ;;AAIA;EACI,aAAA;EACA,kCAAA;EACA,8BAAA;AADJ;;AAOA;ECTgB,aDWc;ECVd,mBDUmB;ECTnB,8BDS0B;AAH1C;;AAMA;EACI,eAAA;AAHJ;;AAMA;EACI,cAAA;AAHJ","sourcesContent":["@import '../../style/mixins';\n\n.cc_textfield{\n    width: 100%;\n    text-transform: uppercase;\n}\n\n.modal-modal-title{\n    font-size: 20px !important;\n    margin-bottom: 25px !important;\n}\n\n.box_modal{\n    border-radius: 12px;\n    max-width: 952px;\n    width: 90%;\n    overflow-y:scroll;\n    overflow-x: hidden;\n    max-height:90%;\n    height: auto;\n}\n\n.cc_modal_head {\n    display: flex;\n    align-items: flex-start !important;\n    justify-content: space-between;\n    // flex-wrap: wrap;\n  }\n  \n\n\n.cc_modal_head{\n    // @include cc-display(flex,center,space-between,wrap);\n    @include cc-display-value(flex,center,space-between)\n}\n\n.modal-close_btn{\n    cursor: pointer;\n}\n\n.modal-close_btn:hover{\n    color: #767676;\n}\n\n\n\n","\n@mixin cc-fontstyle($value,$value1,$value2){\n    font-size:$value;\n    line-height:$value1;\n    font-weight:$value2;\n    }\n\n@mixin cc-display($value,$value1,$value2,$value3){\n        display: $value;\n        align-items: $value1;\n        justify-content: $value2;\n        flex-wrap: $value3;\n        }\n\n        @mixin cc-display-col($value4,$value5,$value6,$value7){\n            display: $value4;\n            flex-direction: $value5;\n            justify-content: $value6;\n            align-items: $value7;\n            }\n        \n        @mixin cc-display-value ($value8,$value9,$value10){\n                display: $value8;\n                align-items: $value9;\n                justify-content: $value10;\n\n            }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
