// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datable_filleter {
  display: flex !important;
  width: 100% !important;
  align-items: center !important;
  justify-content: center;
}

.datable_filleter_box {
  width: 25% !important;
  display: flex !important;
  align-items: center;
}

.table-clear_btn {
  display: flex !important;
  align-items: center !important;
  margin-top: 10px !important;
}

.fillter_label {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  color: var(--cc-gray2) !important;
}

.cc_table th,
.cc_table td {
  min-width: 150px !important;
  width: auto !important;
}

.cc_table_thead th {
  border-bottom: 0;
  padding-bottom: 0;
}

.cc-table-filter td {
  padding-bottom: 0;
}

.cc-table-filter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.0705882353);
}

.cc_table th > div {
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
}
.cc_table th > div > div {
  position: inherit !important;
  top: inherit !important;
  right: inherit !important;
  left: inherit !important;
}

.table_row:hover {
  background-color: #ffffff !important;
  box-shadow: 0px 0px 8px rgba(138, 136, 136, 0.2);
}

.clickable_row:hover {
  cursor: pointer !important;
}

.table_row:hover span {
  color: #122648 !important;
}

.table_row .arrow-icon {
  opacity: 0;
  min-width: inherit !important;
  width: 35px !important;
  font-size: 20px !important;
}

.edit-delete-icon {
  width: 24px !important;
}

.table_row:hover .arrow-icon {
  opacity: 1;
}

.datatable-perpage_box {
  width: 100% !important;
}

.table-clear_btn button {
  opacity: 0 !important;
  cursor: inherit !important;
}

.cc_opicity--full button {
  opacity: 1 !important;
  cursor: pointer !important;
}

.cc-entries__page {
  width: 3rem !important;
}

@media (max-width: 1440px) {
  .head_search {
    display: flex !important;
    overflow-y: scroll;
    align-items: center !important;
  }
  .datatable_perpage {
    width: 100% !important;
  }
  .datatable-perpage_box {
    width: 100% !important;
    min-width: 250px;
  }
  .datatable_filter {
    display: flex !important;
  }
  .table-clear_btn {
    min-width: 200px !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .cc_table th,
  .cc_table td {
    min-width: 180px !important;
    width: auto !important;
    padding-left: 35px !important;
  }
}
.table_row span {
  font-size: 14px !important;
  font-weight: 400 !important;
}/*# sourceMappingURL=index.css.map */`, "",{"version":3,"sources":["webpack://./src/examples/Tables/DataTable/index.scss","webpack://./src/examples/Tables/DataTable/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,sBAAA;EACA,8BAAA;EACA,uBAAA;ACCF;;ADEA;EACE,qBAAA;EACA,wBAAA;EACA,mBAAA;ACCF;;ADEA;EACE,wBAAA;EACA,8BAAA;EACA,2BAAA;ACCF;;ADEA;EACE,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,iCAAA;ACCF;;ADEA;;EAEE,2BAAA;EACA,sBAAA;ACCF;;ADEA;EACE,gBAAA;EACA,iBAAA;ACCF;;ADCA;EACE,iBAAA;ACEF;;ADCA;EACE,oDAAA;ACEF;;ADAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;ACGF;ADDE;EACE,4BAAA;EACA,uBAAA;EACA,yBAAA;EACA,wBAAA;ACGJ;;ADOA;EACE,oCAAA;EAEA,gDAAA;ACLF;;ADQA;EACA,0BAAA;ACLA;;ADQA;EACE,yBAAA;ACLF;;ADQA;EACE,UAAA;EACA,6BAAA;EACA,sBAAA;EACA,0BAAA;ACLF;;ADOA;EACE,sBAAA;ACJF;;ADMA;EACE,UAAA;ACHF;;ADMA;EACE,sBAAA;ACHF;;ADME;EACE,qBAAA;EACA,0BAAA;ACHJ;;ADOE;EACE,qBAAA;EACA,0BAAA;ACJJ;;ADQA;EACE,sBAAA;ACLF;;ADQA;EACE;IACE,wBAAA;IACA,kBAAA;IACA,8BAAA;ECLF;EDOA;IACE,sBAAA;ECLF;EDOA;IACE,sBAAA;IACA,gBAAA;ECLF;EDQA;IACE,wBAAA;ECNF;EDSA;IACE,2BAAA;IACA,sBAAA;ECPF;AACF;ADSA;EACE;;IAEE,2BAAA;IACA,sBAAA;IACA,6BAAA;ECPF;AACF;ADWE;EACE,0BAAA;EACA,2BAAA;ACTJ,CAAA,oCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
