import { useMemo, useContext } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useSortBy, useFilters } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import "../DataTable/index.css";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Box, Button, Grid, Input, LinearProgress } from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import { useNavigate } from "react-router-dom";
import { CommonContext } from "context/CommonCtx";
import { ENTRIES_PER_PAGE_OPTIONS } from "utils/constants";
import { DefaultColumnFilter } from "./filters";
import CsvDownloader from "react-csv-downloader";
import { FileDownload } from "@mui/icons-material";
import moment from "moment";

function LocalDataTable({
  table,
  isSorted,
  noEndBorder,
  isLoading,
  isRowClickable,
  showTotalEntries,
  entriesPerPage,
  isClearAllFilter,
  isCSVExport,
}) {
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ENTRIES_PER_PAGE_OPTIONS;

  const columns = useMemo(() => table.columns, [table]);
  
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    // useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    state: { pageIndex, pageSize, filters: allFilters },
  } = tableInstance;

  const navigate = useNavigate();

  const { CCLoader } = useContext(CommonContext);

  const clickableRow = (row) => {
    if (isRowClickable) {
      // CCLoader();
      // navigate(`/app/roles/${row?.original?.id}`);
    }
  };

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value);
    gotoPage(0);
  };

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input state handle
  // const onSearchChange = useAsyncDebounce((value) => {
  //   setGlobalFilter(value || undefined);
  // }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (column?.sort) {
      if (isSorted && column?.isSorted) {
        sortedValue = column.isSortedDesc ? "desc" : "asce";
      }
      // else {
      // sortedValue = "none";
      // }
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const csvColumns = columns.map((column, index) => {
    return {
      id: column.accessor,
      displayName: column.Header,
    };
  });

  const getCsvRows = () => {
    const csvData = page.map((row, index) => {
      const temp = { ...row.values };

      return temp;
    });

    return Promise.resolve(csvData);
  };

  return (
    <>
      {entriesPerPage ? (
        <MDBox className="cc-filter_record" p={2}>
          <MDBox>
            {entriesPerPage && (
              <MDBox display="flex" alignItems="center">
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="secondary" sx={{ textWrap: "nowrap" }}>
                  &nbsp;&nbsp;entries per page
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
          <MDBox display="flex" alignItems="center">
            <MDBox display="flex" alignItems="center">
              {isCSVExport && (
                <CsvDownloader
                  filename="tenant-invoice"
                  extension=".csv"
                  separator=";"
                  columns={csvColumns}
                  datas={getCsvRows}
                >
                  <Button variant="outlined" startIcon={<FileDownload />}>
                    Export to CSV
                  </Button>
                </CsvDownloader>
              )}

              {isClearAllFilter && (
                <Button
                  // disabled={!allFilters.length}
                  onClick={(e) => {
                    setAllFilters([]);
                  }}
                  startIcon={<CloseIcon />}
                >
                  clear All
                </Button>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      <TableContainer sx={{ boxShadow: "none" }}>
        {/* {!loading && <LinearProgress color="secondary2" sx={{ mt: 0 }} />} */}
        <Table {...getTableProps()} className={`cc_table`}>
          {!isLoading && (
            <MDBox component="thead">
              {headerGroups.map((headerGroup, key) => (
                <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, idx) => (
                    <DataTableHeadCell
                      className="cc-table-row"
                      key={idx}
                      {...column.getHeaderProps(
                        (isSorted || column.sort) && column.getSortByToggleProps()
                      )}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                      sx={{ borderBottom: "0 !important" }}
                      inputType={column.filterInputType}
                      inputOptions={column.filterOptions}
                      sortable={column.sortable}
                      column={column}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </MDBox>
          )}

          {table.rows.length > 0 && (
            <TableBody {...getTableBodyProps()}>
              {page.map((row, key) => {
                prepareRow(row);
                return (
                  <TableRow
                    onClick={() => clickableRow(row)}
                    className={`table_row ${isRowClickable ? "clickable_row" : ""} `}
                    key={key}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, idx) => (
                      <DataTableBodyCell
                        key={idx}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>

        {rows.length > 0 ? (
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
          >
            {showTotalEntries && (
              <MDBox mb={{ xs: 3, sm: 0 }}>
                <MDTypography variant="button" color="secondary" fontWeight="regular">
                  Showing {entriesStart} to {entriesEnd < rows.length ? entriesEnd : rows.length} of{" "}
                  {rows.length} entries
                </MDTypography>
              </MDBox>
            )}
            {pageOptions.length > 1 && (
              <MDPagination variant="gradient" color="primary">
                {canPreviousPage && (
                  <MDPagination item onClick={() => previousPage()}>
                    <Icon sx={{ fontWeight: "bold", cursor: "pointer" }}>chevron_left</Icon>
                  </MDPagination>
                )}
                {renderPagination.length > 6 ? (
                  <MDBox width="5rem" mx={1} className="roundPaginationButton">
                    <MDInput
                      inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                      value={customizedPageOptions[pageIndex]}
                      onChange={(handleInputPagination, handleInputPaginationValue)}
                    />
                  </MDBox>
                ) : (
                  renderPagination
                )}
                {canNextPage && (
                  <MDPagination item onClick={() => nextPage()}>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                  </MDPagination>
                )}
              </MDPagination>
            )}
          </MDBox>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              m: "1rem 0 1rem 0",
            }}
          >
            <MDTypography variant="h4" fontWeight="medium">
              Data not found!
            </MDTypography>
          </Grid>
        )}
      </TableContainer>
    </>
  );
}

// Setting default values for the props of LocalDataTable
LocalDataTable.defaultProps = {
  table: [],
  isSorted: true,
  noEndBorder: false,
  isLoading: false,
  isRowClickable: false,
  showTotalEntries: true,
  entriesPerPageOption: ENTRIES_PER_PAGE_OPTIONS,
  entriesPerPage: 10,
  handlePageSizeChange: () => {},
  totalPages: 1,
  currentPage: 0,
  handlePageChange: () => {},
  sorting: [],
  handleSortByChange: () => {},
  filters: [],
  handleFiltersChange: () => {},
  isClearAllFilter: true,
  // handleClearAllFilters: () => {},
  getData: () => {},
  loading: false,
};

// Typechecking props for the LocalDataTable
LocalDataTable.propTypes = {
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRowClickable: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  entriesPerPageOption: PropTypes.arrayOf(PropTypes.number),
  entriesPerPage: PropTypes.number,
  handlePageSizeChange: PropTypes.func,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageChange: PropTypes.func,
  sorting: PropTypes.objectOf(PropTypes.array),
  handleSortByChange: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.array),
  handleFiltersChange: PropTypes.func,
  isClearAllFilter: PropTypes.bool,
  getData: PropTypes.func,
  loading: PropTypes.bool,
};

export default LocalDataTable;
