import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

// Get Plans List API function
export async function getPlans(data) {
  try {
    const response = await callPostApi({ url: "plan/list", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Add Plans API function
export async function addPlan(data) {
  try {
    const response = await callPostApi({ url: "plan/add", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Plans By Id API function
export async function getPlanById(id) {
  try {
    const response = await callGetApi({ url: `plan/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update Plans API function
export async function updatePlan(id, data) {
  try {
    const response = await callPutApi({ url: `plan/update/${id}`, body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Delete Plans API function
export async function deletePlan(id) {
  try {
    const response = await callDeleteApi({ url: `plan/delete/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get All Plan Types API function
export async function getAllPlanItems() {
  try {
    const response = await callGetApi({ url: "global/items" });
    return response;
  } catch (error) {
    throw error;
  }
}
