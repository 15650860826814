// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Data
import { Autocomplete, Button, Grid, MenuItem, Switch, TextField, Typography } from "@mui/material";

import { useContext, useEffect, useState } from "react";

import { Link, redirect, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Divider } from "rsuite";
import { addService } from "lib/Api/Services";
import { LoginFormContext } from "context/loginFormCtx";
import { VALIDATION_MESSAGES } from "utils/constants";
import { updateService } from "lib/Api/Services";
import { getNamesOfServiceTypes } from "lib/Api/ServiceTypes";

function AddEditServiceForm({ serviceData }) {
  const { setNotification } = useContext(LoginFormContext);
  const [typeOptions, setTypeOptions] = useState([]);

  const navigate = useNavigate();

  // const typeOptions = [
  //   { label: "Service", id: 1 },
  //   { label: "Product", id: 2 },
  // ];

  const unitOptions = [
    { id: 1, label: "Hours" },
    { id: 2, label: "Days" },
  ];

  const formik = useFormik({
    initialValues: {
      name: serviceData?.name || "",
      description: serviceData?.description || "",
      type: serviceData?.type || "",
      duration: serviceData?.duration || "",
      unit: serviceData?.unit || "",
      price: serviceData?.price || "",
      discount: serviceData?.discount || 0,
      status: serviceData ? serviceData?.status : true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(VALIDATION_MESSAGES.SERVICES.NAME.REQUIRED),
      type: Yup.object().required(VALIDATION_MESSAGES.SERVICES.TYPE.REQUIRED),
      duration: Yup.number().required(VALIDATION_MESSAGES.SERVICES.DURATION.REQUIRED),
      unit: Yup.number().required(VALIDATION_MESSAGES.SERVICES.UNIT.REQUIRED),
      price: Yup.number().required(VALIDATION_MESSAGES.SERVICES.PRICE.REQUIRED),
      discount: Yup.number().min(0).max(99),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);

        const temp = {
          ...values,
          service_type_id: +values.type.id,
          type: values.type.id,
          status: +values.status,
        };

        let response;
        if (serviceData) {
          response = await updateService(serviceData.id, temp);
        } else {
          response = await addService(temp);
        }

        if (response && response.status === 200) {
          setSubmitting(false);
          resetForm();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
          navigate("/services");
        } else {
          setSubmitting(false);
          setNotification({
            color: "error",
            icon: "cancel",
            title: "Error",
            content: response.data.message,
            open: true,
          });
        }
      } catch (error) {
        console.log("Error: ", error);
        setSubmitting(false);
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: error.message || "Something went wrong!",
          open: true,
        });
      }
    },
  });

  const serviceTypeNameHandler = async () => {
    const response = await getNamesOfServiceTypes();
    if (response?.status === 200) {
      setTypeOptions(response?.data?.data?.rows ?? []);
    }
  };

  useEffect(() => {
    serviceTypeNameHandler();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <TextField
              type="text"
              value={formik.values.name}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item md={4} xs={12} sx={{ height: "10px" }}>
            <Autocomplete
              sx={{ height: "10px" }}
              value={formik.values.type}
              options={typeOptions}
              onChange={(event, newValue) => {
                formik.setFieldValue("type", newValue);
              }}
              placeholder="Select Srevice Type"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Service Type"
                  error={Boolean(formik.touched.type && formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                />
              )}
              required
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              type="number"
              value={formik.values.duration}
              error={Boolean(formik.touched.duration && formik.errors.duration)}
              helperText={formik.touched.duration && formik.errors.duration}
              fullWidth
              label="Duration"
              name="duration"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              select
              value={formik.values.unit}
              error={Boolean(formik.touched.unit && formik.errors.unit)}
              helperText={formik.touched.unit && formik.errors.unit}
              fullWidth
              label="Duration Unit"
              name="unit"
              onBlur={formik.handleBlur}
              onChange={(event, value) => {
                formik.setFieldValue(`unit`, event.target.value);
              }}
            >
              {unitOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              type="number"
              value={formik.values.price}
              error={Boolean(formik.touched.price && formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
              fullWidth
              label="Price"
              name="price"
              inputProps={{ min: 0 }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              type="number"
              value={formik.values.discount}
              error={Boolean(formik.touched.discount && formik.errors.discount)}
              helperText={formik.touched.discount && formik.errors.discount}
              fullWidth
              label="Discount"
              name="discount"
              inputProps={{ min: 0, max: 99 }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              type="text"
              multiline
              rows={7}
              value={formik.values.description}
              error={Boolean(formik.touched.description && formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              label="Description"
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>

          {serviceData && (
            <Grid item md={4} xs={12}>
              <Grid container>
                <Typography variant="subtitle1">Status:</Typography>
                <Grid item>
                  <Switch
                    checked={formik.values.status}
                    inputProps={{ "aria-label": "controlled" }}
                    name="status"
                    label="Status"
                    onChange={(event) => {
                      formik.setFieldValue(`status`, event.target.checked);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Divider />
        <MDBox sx={{ mt: 2 }} display="flex" justifyContent="center" alignItems="center">
          <Button disabled={formik.isSubmitting} type="submit" sx={{ m: 1 }} variant="contained">
            {serviceData ? "Save" : "Add Service"}
          </Button>

          <Link to="/services">
            <Button variant="outlined">Cancel</Button>
          </Link>
        </MDBox>
      </form>
    </>
  );
}

export default AddEditServiceForm;
