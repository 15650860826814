import { useContext } from "react";
import { useFormik } from "formik";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import AuthLayout from "layouts/authentication/components/AuthLayout";

// Image
import ccLogo from "assets/cc-images/form-side-img.svg";
import { Box } from "@mui/material";
import { initialValues } from "./schemas/initialValue";
import { ValidationSchema } from "./schemas/validation";
import { LoginFormContext } from "context/loginFormCtx";
import { resetPasswordApi } from "lib/Api/Authentication";

function ResetPassword() {
  const { setNotification, userId } = useContext(LoginFormContext);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    values.id = userId;
    const response = await resetPasswordApi(values);
    if (response?.status === 200) {
      navigate("/login");
      setNotification({
        color: "success",
        icon: "check",
        title: "Success",
        content: response.data.message,
        open: true,
      });
    } else {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: response.data.message,
        open: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthLayout
        title="Confirm Password"
        description="Enter your new password"
        illustration={ccLogo}
      >
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              values={formik.values.new_password}
              name="new_password"
              label="New Password"
              fullWidth
              error={formik.touched.new_password && formik.errors.new_password ? true : false}
              onChange={formik.handleChange}
            />
            {formik.touched.new_password && formik.errors.new_password ? (
              <Box component="p" sx={{ color: "#F44335", fontSize: "0.75rem", fontWeight: "300" }}>
                {formik.errors.new_password}
              </Box>
            ) : (
              ""
            )}
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              values={formik.values.confirm_password}
              name="confirm_password"
              label="Confirm Password"
              fullWidth
              error={
                formik.touched.confirm_password && formik.errors.confirm_password ? true : false
              }
              onChange={formik.handleChange}
            />
            {formik.touched.confirm_password && formik.errors.confirm_password ? (
              <Box component="p" sx={{ color: "#F44335", fontSize: "0.75rem", fontWeight: "300" }}>
                {formik.errors.confirm_password}
              </Box>
            ) : (
              ""
            )}
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="primary" size="large" fullWidth>
              Submit
            </MDButton>
          </MDBox>
        </MDBox>
      </AuthLayout>
    </form>
  );
}

export default ResetPassword;
