import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import FormField from "layouts/pages/account/components/FormField";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ValidationSchema } from "layouts/pages/service-type/schemas/validationSchema";
import { InitialValues } from "layouts/pages/service-type/schemas/initialValues";
import { addServiceTypes } from "lib/Api/ServiceTypes";
import { LoginFormContext } from "context/loginFormCtx";
import { updateServiceTypes } from "lib/Api/ServiceTypes";
import { getServiceTypesById } from "lib/Api/ServiceTypes";

const EditServiceTypeForm = (props) => {
  const { setNotification } = useContext(LoginFormContext);
  const [isChecked, setIsChecked] = useState();

  const handleSubmit = async (values) => {
    values.status = isChecked ? 1 : 2;
    let response = await updateServiceTypes(values);

    if (response?.status === 200) {
      props?.setOpenEditForm(false);

      props?.getServiceTypeData();

      setNotification({
        color: "success",
        icon: "check",
        title: "Success",
        content: response.data.message,
        open: true,
      });
    } else {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: response.data.message,
        open: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleServiceTypes = async (id) => {
    const response = await getServiceTypesById(id);
    if (response.status === 200) {
      formik.setValues(response.data.data);

      if (response.data.data.status == 1) {
        setIsChecked(true);
      }
    }
  };

  useEffect(() => {
    handleServiceTypes(props?.serviceTypeId);
  }, [props?.serviceTypeId]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                value={formik.values.name}
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Name"
                fullWidth
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                value={formik.values.description}
                error={Boolean(formik.touched.description && formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label="Description"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              Status:
              <Switch
                checked={isChecked}
                inputProps={{ "aria-label": "controlled" }}
                name="status"
                onChange={() => {
                  setIsChecked(!isChecked);
                }}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ justifyContent: "center", gap: "1rem" }}>
            <MDButton type="submit" color="primary" size="medium">
              Edit Service Type
            </MDButton>
            <MDButton
              color="primary"
              variant="outlined"
              size="medium"
              onClick={() => props?.setOpenEditForm(false)}
            >
              Cancel
            </MDButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditServiceTypeForm;
