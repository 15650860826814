import moment from "moment";

const currentDate = new Date();

export const PREDEFINED_RANGES = [
  {
    label: "This month",
    closeOverlay: false,
    value: [moment().startOf("month").toDate(), currentDate],
    placement: "bottom",
  },
  {
    label: "Last month",
    closeOverlay: false,
    value: [
      moment().subtract(1, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
    placement: "bottom",
  },
  {
    label: "This year",
    closeOverlay: false,
    value: [moment().startOf("year").toDate(), currentDate],
    placement: "bottom",
  },
  {
    label: "Last year",
    closeOverlay: false,
    value: [
      moment().subtract(1, "year").startOf("year").toDate(),
      moment().subtract(1, "year").endOf("year").toDate(),
    ],
    placement: "bottom",
  },
];
