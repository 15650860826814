import { VALIDATION_MESSAGES } from "utils/constants";
import * as Yup from "yup";

export const validations = Yup.object({
  email: Yup.string().email().required(VALIDATION_MESSAGES.LOGIN.USER_NAME.REQUIRED),
  password: Yup.string()
    .required(VALIDATION_MESSAGES.LOGIN.PASSWORD.REQUIRED)
    .min(8, VALIDATION_MESSAGES.LOGIN.PASSWORD.MIN_LENGTH),
});

export default validations;
