import { Grid } from "@mui/material";
import FormBuilderComponent from "components/FormBuilder/FormBuilder";
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import React from "react";

const FormBuilder = () => {
  return (
    <CustomPageLayout>
      <FormBuilderComponent />
    </CustomPageLayout>
  );
};

export default FormBuilder;
