import { Button, Card, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "examples/Tables/DataTable";
import { getFiltersFromCache } from "utils/cacheManager";
import { TABLE_FILTERS } from "utils/constants";
import { saveFiltersToCache } from "utils/cacheManager";
import { getFormList } from "lib/Api/FormBuilder";
import MDButton from "components/MDButton";
import DefaultCell from "examples/Tables/components/DefaultCell";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { getSortByFromCache } from "utils/cacheManager";
import { saveSortByToCache } from "utils/cacheManager";
import { STATUS_BUTTON_VALUE } from "utils/constants";
import { LoginFormContext } from "context/loginFormCtx";
import moment from "moment";
import { deleteForm } from "lib/Api/FormBuilder";
import { updateFormStatus } from "lib/Api/FormBuilder";

const FormList = () => {
  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(getFiltersFromCache(TABLE_FILTERS.FORM_BUILDER) || []);
  // const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState(getSortByFromCache(TABLE_FILTERS.FORM_BUILDER) || []);

  const navigate = useNavigate();

  const handleDelete = async (id) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to delete this form?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteForm(id);

        if (response?.status === 200) {
          getForms();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const handleStatusChange = async (id, status) => {
    showMessageNotification({
      title: "Are you sure?",
      text: "You want to change the status of this form?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--cc-confirm-button-color)",
      cancelButtonColor: "var(--cc-cancel-button-color)",
      confirmButtonText: "Yes, change it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateFormStatus({ id, status: status ? 0 : 1 });

        if (response?.status === 200) {
          getForms();
          setNotification({
            color: "success",
            icon: "check",
            title: "Success",
            content: response.data.message,
            open: true,
          });
        } else {
          setNotification({
            color: "error",
            icon: "check",
            title: "error",
            content: response.data.message,
            open: true,
          });
        }
      }
    });
  };

  const FORM_LIST_TABLE_COLUMNS = {
    columns: [
      {
        Header: "Id",
        accessor: "id",
        filterInputType: "number",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={`# ${value}`} />,
      },
      {
        Header: "Name",
        accessor: "name",
        filterInputType: "text",
        sort: true,
        Cell: ({ value, row }) => {
          return (
            <>
              {/* <Link to={`/roles/${row.values.id}`}> */}
              <DefaultCell value={value} />
              {/* </Link> */}
            </>
          );
        },
      },
      {
        Header: "Service",
        accessor: "service",
        filterInputType: "text",
        sort: true,
        Cell: ({ value, row }) => {
          return (
            <>
              {/* <Link to={`/roles/${row.values.id}`}> */}
              <DefaultCell value={value.name} />
              {/* </Link> */}
            </>
          );
        },
      },
      {
        Header: "status",
        accessor: "status",
        filterInputType: "select",
        filterOptions: [
          { label: "Inactive", value: 0 },
          { label: "Active", value: 1 },
        ],
        Cell: ({ value, row }) => (
          <MDButton
            color={STATUS_BUTTON_VALUE[value].color}
            sx={{
              background: `${STATUS_BUTTON_VALUE[value].background}`,
            }}
            size="small"
            circular={true}
            onClick={() => {
              handleStatusChange(row.original.id, value);
            }}
          >
            {STATUS_BUTTON_VALUE[value].label}
          </MDButton>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={moment(value).format("MM/DD/YYYY")} />,
      },
      {
        Header: "Updated At",
        accessor: "updated_at",
        filterInputType: "date",
        sort: true,
        Cell: ({ value }) => <DefaultCell value={moment(value).format("MM/DD/YYYY")} />,
      },
      {
        Header: "Action",
        width: "3%",
        sort: false,
        Cell: ({ value, row }) => {
          return (
            <MDBox>
              <IconButton
                onClick={() => {
                  navigate(`/form-builder/edit-form/${row.values.id}`);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleDelete(row.values.id);
                }}
              >
                <Delete />
              </IconButton>
            </MDBox>
          );
        },
      },
    ],
    rows: [],
  };

  const [tableData, setTableData] = useState({
    columns: FORM_LIST_TABLE_COLUMNS.columns,
    rows: [],
    count: 0,
  });

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.FORM_BUILDER, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.FORM_BUILDER, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.FORM_BUILDER, sortObject);
  };

  const getForms = async () => {
    try {
      // setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (filter.id == "status") {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getFormList(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.rows,
          count: response.data.data.count,
        });
        setTotalPages(Math.ceil(response.data.data.count / pageSize));

        // setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
    }
  };

  const handleLoad = () => {
    setIsLoading(true);

    if (tableData.rows.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getForms();
  }, [page, pageSize, filters, sortBy]);

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Manage Forms
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the forms.
                </MDTypography>
              </Grid>
              <Grid item>
                <Link to="/form-builder/create-form">
                  <Button variant="outlined" startIcon={<AddIcon />}>
                    Add Form
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </MDBox>
          {!isLoading && (
            <DataTable
              isLoading={isLoading}
              table={tableData}
              pageSize={pageSize}
              currentPage={page}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              filters={filters}
              handleFiltersChange={handleFiltersChange}
              sorting={sortBy}
              handleSortByChange={handleSortByChange}
              getData={getForms}
              handleClearAllFilters={handleClearAllFilters}
              loading={isLoading}
            />
          )}
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
};

export default FormList;
