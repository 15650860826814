import { Close } from "@mui/icons-material";
import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React from "react";
// import { Input, CustomInput } from "reactstrap";

export const Filter = ({ column }) => {
  return <div style={{ marginTop: 5 }}>{column.canFilter && column.render("Filter")}</div>;
};

export const DefaultColumnFilter = ({
  column: { filterValue, setFilter, filterInputType, filterOptions, id },
}) => {
  let inputComponent = null;

  switch (filterInputType) {
    case "text":
      inputComponent = (
        <TextField
          type="text"
          value={filterValue || ""}
          size="small"
          variant="outlined"
          placeholder={`Filter by ${id}`}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                disabled={!filterValue}
                size="small"
                onClick={() => {
                  setFilter(undefined);
                }}
              >
                <Close />
              </IconButton>
            ),
          }}
        />
      );
      break;
    case "number":
      inputComponent = (
        <TextField
          type="number"
          value={filterValue || ""}
          size="small"
          variant="outlined"
          placeholder={`Filter by ${id}`}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                disabled={!filterValue}
                size="small"
                onClick={() => {
                  setFilter(undefined);
                }}
              >
                <Close />
              </IconButton>
            ),
          }}
        />
      );
      break;
    case "select":
      inputComponent = (
        <Autocomplete
          value={filterValue || null}
          options={filterOptions}
          onChange={(e, newValue) => {
            setFilter(newValue?.value || undefined);
          }}
          placeholder={`Filter by ${id}`}
          size="small"
          sx={{ width: "auto", minWidth: 150 }}
          renderInput={(params) => <TextField {...params} placeholder={`Filter by ${id}`} />}
        />
      );
      break;
    case "date":
      const date = filterValue ? moment(filterValue) : null;
      inputComponent = (
        <DatePicker
          value={date}
          onChange={(newValue) => {
            const tempNewValue = newValue ? moment(newValue).format("MM/DD/YYYY") : null;
            setFilter(tempNewValue || undefined);
          }}
          sx={{ width: 200 }}
          format="MM/DD/YYYY"
          inputRef={{ size: "small" }}
          slotProps={{
            textField: {
              size: "small",
              clearable: true,
              onClear: (event) => {},
            },
          }}
        />
      );
      break;
    default:
      inputComponent = (
        <>
          <Box sx={{ mb: 4.5 }}></Box>
        </>
      );
  }

  return inputComponent;
};
