import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

export const TinyTextEditor = (props) => {
  const editorRef = useRef(null);

  // const handleUpload = async (blobInfo, success, failure) => {
  //     return new Promise((resolve, reject) => {
  //         let formData = new FormData();
  //         formData.append("folderName", props?.folderName);
  //         formData.append("file", blobInfo.blob(), blobInfo.filename());
  //         imageUploadAPI(formData).then(response => {
  //             resolve(response.data.data[0].url);
  //         })
  //             .catch(e => {
  //                 reject(e);
  //             });
  //     });
  // };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_API_KEY}
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={props.content}
      disabled={props?.disabled}
      onBlur={(e) => {
        props.setContent(e.target.getContent());
      }}
      plugins="advlist autolink lists link image charmap print preview anchor,searchreplace visualblocks code fullscreen,insertdatetime media table paste code help wordcount image imagetools preview table"
      init={{
        // images_upload_handler : handleUpload,
        height: 300,
        menubar: true,
        toolbar: `image | undo redo | formatselect | bold italic backcolor forecolor |
                alignleft aligncenter alignright alignjustify |
                bullist numlist outdent indent | removeformat | help | code | preview | table`,
        image_caption: true,
        contextmenu: false,
      }}
    />
  );
};
