// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button } from "@mui/material";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import { useFormik } from "formik";
import { initialValues } from "./schemas/initialValue";
import { ValidationSchema } from "./schemas/validation";
import { useContext, useEffect, useState } from "react";

import { LoginFormContext } from "context/loginFormCtx";
import { getUserProfile, userProfileUpdate } from "lib/Api/Authentication";

function BasicInfo() {
  const { userDetail, setUserDetail, setNotification } = useContext(LoginFormContext);

  // formik onsubmit ...
  const handleSubmit = async (values) => {
    console.log("values: ", values);
    values.client_id = userDetail.client_id;
    values.role_id = userDetail.role_id;

    let response = await userProfileUpdate(values);
    console.log("response: ", response);

    if (response?.status === 200) {
      setNotification({
        color: "success",
        icon: "check",
        title: "Success",
        content: response.data.message,
        open: true,
      });
      getUserData();
      localStorage.setItem("user_data", JSON.stringify(values));
    } else {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: response.data.message,
        open: true,
      });
    }
  };

  const getUserData = async () => {
    let response = await getUserProfile();
    if (response?.status === 200) {
      let array = [];
      response?.data?.data?.user_areas_relations?.map((item) => {
        array.push({ id: parseInt(item?.area?.id), label: item?.area?.name });
      });

      setUserDetail(response.data.data);

      // get all value from api and store in formik...
      formik.setValues(response.data.data);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  // Formik validation of user profile...
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Profile Info</MDTypography>
      </MDBox>
      <MDBox onSubmit={formik.handleSubmit} component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              value={formik.values.first_name}
              error={formik.touched.first_name && formik.errors.first_name ? true : false}
              helperText={formik.errors.first_name}
              label="First Name"
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={formik.values.last_name}
              error={formik.touched.last_name && formik.errors.last_name ? true : false}
              helperText={formik.errors.last_name}
              label="Last Name"
              name="last_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={formik.errors.email}
              label="Email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </Grid>
        <MDBox ml="auto" mt="30px" sx={{ display: "flex", justifyContent: "end" }}>
          <Button type="submit" size="small" className="personnel-submit_btn">
            update
          </Button>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
