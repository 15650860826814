import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

// Get Tenants List API function
export async function getTenants(data) {
  try {
    const response = await callPostApi({ url: "tenant/list", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Add Tenant API function
export async function addTenant(data) {
  try {
    const response = await callPostApi({ url: "tenant/add", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Tenant By Id API function
export async function getTenantById(id) {
  try {
    const response = await callGetApi({ url: `tenant/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Delete Tenant API function
export async function deleteTenant(id) {
  try {
    const response = await callDeleteApi({ url: `tenant/delete/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update Tenant API function
export async function updateTenant(data) {
  try {
    const response = await callPutApi({ url: "tenant/update", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update Tenant Status API function
export async function updateTenantStatus(id, data) {
  try {
    const response = await callPutApi({ url: `tenant/update-status/${id}`, body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
