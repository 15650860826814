import { callGetApi, callPostApi, callPutApi } from "../interceptor";

// Login form API function
export async function userLogin(data) {
  try {
    const response = await callPostApi({ url: "users/sign_in", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Logout API function
export async function logout() {
  try {
    const response = await callPostApi({ url: "users/sign_out" });
    return response;
  } catch (error) {
    throw error;
  }
}

// Forgot password API function
export async function forgotPasswordApi(data) {
  try {
    const response = await callPostApi({ url: "users/forgot_password", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// send reminder API function
export async function sendReminderApi(data) {
  try {
    const response = await callPostApi({ url: "users/send-reminder", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Forgot password OTP API function
export async function forgotPasswordOtpApi(data) {
  try {
    const response = await callPostApi({ url: "users/forgot_password/otp", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// OTP verification API function
export async function otpVerificationApi(data) {
  try {
    const response = await callPostApi({ url: "users/otp_verification", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Reset password With OTP API function
export async function resetPasswordApi(data) {
  try {
    const response = await callPutApi({
      url: "users/otp_verification/reset_password",
      body: data,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get user profile API function
export async function getUserProfile() {
  try {
    const response = await callGetApi({ url: "users/get_profile" });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update user profile API function
export async function userProfileUpdate(data) {
  try {
    const response = await callPutApi({ url: "users/update_profile", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function userProfileChangePassword(data) {
  try {
    const response = await callPostApi({ url: "users/change_password", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
