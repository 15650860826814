import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

// Get Coupon List API function
export async function getCoupons(data) {
  try {
    const response = await callPostApi({ url: "coupons/list", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Add Coupon API function
export async function addCoupon(data) {
  try {
    const response = await callPostApi({ url: "coupons/add", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Coupon By Id API function
export async function getCouponById(id) {
  try {
    const response = await callGetApi({ url: `coupons/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Update Coupon API function
export async function updateCoupon(id, data) {
  try {
    const response = await callPutApi({ url: `coupons/update/${id}`, body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// Delete Coupon API function
export async function deleteCoupon(id) {
  try {
    const response = await callDeleteApi({ url: `coupons/delete/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get All Plan Types API function
// export async function getAllCouponItems() {
//   try {
//     const response = await callGetApi({ url: "global/items" });
//     return response;
//   } catch (error) {
//     throw error;
//   }
// }
