export const checkAllAccess = (permissions, parentModuleIdx, rwdAccess) => {
  try {
    const trueCount = permissions[parentModuleIdx].subModules.filter(
      (item) => item[rwdAccess] === true
    ).length;

    // 0 = no access, 1 = all access, 2 = partial access
    let isAllAccess;
    if (trueCount === permissions[parentModuleIdx].subModules.length) {
      isAllAccess = 1;
    } else if (trueCount === 0) {
      isAllAccess = 0;
    } else {
      isAllAccess = 2;
    }

    return isAllAccess;
  } catch (error) {
    console.error("Error (checkAllAccess): ", error);
  }
};
