// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDModal from "components/MDModal";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";
// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Button, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";

import { useContext, useEffect, useRef, useState } from "react";
import { ModalContext } from "context/ModalCtx";

import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { getServiceTypes } from "lib/Api/ServiceTypes";
import AddServiceTypeForm from "components/ServiceTypes/AddServiceTypeForm";

import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import DefaultCell from "examples/Tables/components/DefaultCell";
import moment from "moment";
import { STATUS_BUTTON_VALUE } from "utils/constants";
import { LoginFormContext } from "context/loginFormCtx";
import { deleteServiceTypes } from "lib/Api/ServiceTypes";
import { SERVICE_TYPES_ACTIONS } from "utils/constants";
import EditServiceTypeForm from "components/ServiceTypes/EditServiceTypeForm";
import { updateServiceTypes } from "lib/Api/ServiceTypes";
import { TABLE_FILTERS } from "utils/constants";
import {
  getFiltersFromCache,
  saveFiltersToCache,
  getSortByFromCache,
  saveSortByToCache,
} from "utils/cacheManager";
import { getServiceTypeRequestsAPI } from "lib/Api/ServiceTypes";
import { getTenants } from "lib/Api/Tenants";
import StatusCell from "examples/Tables/components/StatusCell";
import { PROGRESS_STATUS_BUTTON_VALUE } from "utils/constants";
import { updateProgressStatusAPI } from "lib/Api/ServiceTypes";

function ServiceTypeRequests() {
  const { setNotification, showMessageNotification } = useContext(LoginFormContext);

  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editFormDataValues, setEditFormDataValue] = useState(null);
  const [statusValue, setStatusValue] = useState(null);

  const [updatedColumns, setUpdatedColumns] = useState([]);

  const [dataTableColumns, setDataTableColumns] = useState([
    {
      Header: "Title",
      accessor: "title",
      filterInputType: "text",
      sort: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Description",
      accessor: "description",
      filterInputType: "text",
      sort: true,
      Cell: ({ value }) => {
        if (value.length > 15) {
          const sliceString = `${value.slice(0, 20)}...`;
          return <div style={{ maxWidth: "300px" }}>{sliceString}</div>;
        }
        return <div style={{ maxWidth: "300px" }}>{value}</div>;
      },
    },
    {
      Header: "User",
      accessor: "user_id",
      filterInputType: "select",
      filterOptions: [],
      sort: true,
      Cell: ({ value, row }) => {
        return (
          <DefaultCell value={`${row.original.user.first_name} ${row.original.user.last_name}`} />
        );
      },
    },
    {
      Header: "Progress Status",
      accessor: "progress_status",
      filterInputType: "select",
      filterOptions: [
        { value: 2, label: "Approved" },
        { value: 1, label: "Pending" },
        { value: 0, label: "Rejected" },
      ],
      sort: true,
      Cell: ({ value }) => (
        <MDButton
          color={PROGRESS_STATUS_BUTTON_VALUE[value].color}
          sx={{
            background: `${PROGRESS_STATUS_BUTTON_VALUE[value].background}`,
            cursor: "default",
          }}
          size="small"
          circular={true}
        >
          {PROGRESS_STATUS_BUTTON_VALUE[value].label}
        </MDButton>
      ),
    },
    {
      Header: "Created At",
      accessor: "created_at",
      filterInputType: "date",
      sort: true,
      Cell: ({ value, row }) => {
        return moment(value).format("MM/DD/YYYY");
      },
    },
    {
      Header: "Updated At",
      accessor: "updated_at",
      filterInputType: "date",
      sort: true,
      Cell: ({ value, row }) => {
        return moment(value).format("MM/DD/YYYY");
      },
    },
    {
      Header: "Actions",
      sort: false,
      Cell: ({ value, row, column }) => {
        return (
          <MDBox>
            <IconButton
              onClick={() => {
                handleEdit(row.original);
              }}
            >
              <Visibility />
            </IconButton>
          </MDBox>
        );
      },
    },
  ]);

  const [tableData, setTableData] = useState({
    columns: dataTableColumns,
    rows: [],
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(
    getFiltersFromCache(TABLE_FILTERS.SERVICE_TYPES_REQUESTS) || []
  );
  const [sortBy, setSortBy] = useState(
    getSortByFromCache(TABLE_FILTERS.SERVICE_TYPES_REQUESTS) || []
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const handleFiltersChange = (filterObject) => {
    setFilters(filterObject);
    saveFiltersToCache(TABLE_FILTERS.SERVICE_TYPES_REQUESTS, filterObject);
    setPage(0);
  };

  const handleClearAllFilters = () => {
    setFilters([]);
    saveFiltersToCache(TABLE_FILTERS.SERVICE_TYPES_REQUESTS, []);
    setPage(0);
  };

  const handleSortByChange = (sortObject) => {
    setSortBy(sortObject);
    saveSortByToCache(TABLE_FILTERS.SERVICE_TYPES_REQUESTS, sortObject);
  };

  const getServiceTypeRequestData = async () => {
    try {
      setIsLoading(true);

      const sortId = sortBy[0]?.id;
      const sortVal = sortBy[0]?.desc ? "desc" : "asc";

      const updatedFilters = filters.map((filter) => {
        if (filter.id == "progress_status" || filter.id == "user_id") {
          return {
            ...filter,
            value: filter.value.value,
          };
        }
        return filter;
      });

      const filterBody = {
        page: page + 1,
        pageSize,
        filters: updatedFilters,
        sortBy: sortId ? [{ id: sortId, value: sortVal }] : [],
      };

      const response = await getServiceTypeRequestsAPI(filterBody);

      if (response && response.status === 200) {
        setTableData({
          ...tableData,
          rows: response.data.data.rows,
        });
        setTotalPages(Math.ceil(response.data.data.count / pageSize));

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleEdit = (values) => {
    setEditFormDataValue(values);
    setOpenEditForm(true);
  };

  const handleProgressStatusChange = async (event) => {
    try {
      const changedValue = event?.target?.value;
      setStatusValue(changedValue);

      const response = await updateProgressStatusAPI(editFormDataValues?.id, {
        progress_status: changedValue,
      });

      if (response?.status === 200) {
        setNotification({
          color: "success",
          icon: "check",
          title: "Success",
          content: response.data.message,
          open: true,
        });
        getServiceTypeRequestData();
      } else {
        setNotification({
          color: "error",
          icon: "check",
          title: "error",
          content: response.data.message,
          open: true,
        });
      }
    } catch (error) {
      setNotification({
        color: "error",
        icon: "check",
        title: "error",
        content: "Something went wrong while processing your request. Please try again later.",
        open: true,
      });
    }
  };

  const getTenantsData = async () => {
    try {
      const response = await getTenants();

      if (response && response.status === 200) {
        const tenants = response.data.data.tenants;

        const resultArray = [];

        tenants.forEach((item) => {
          item.tenantUsers.forEach((user) => {
            resultArray.push({
              label: `${user.user.first_name} ${user.user.last_name}`,
              value: parseInt(user.user.id),
            });
          });
        });

        const tempColumns = [...dataTableColumns];

        tempColumns[2].filterOptions = resultArray;

        setDataTableColumns(tempColumns);
      }
    } catch (error) {
      console.log("error (getTenantsData): ", error);
    }
  };

  const renderModalView = (
    <>
      <Stack spacing={2} direction="column">
        <Typography variant="h6">Title</Typography>
        <Typography variant="body2">{editFormDataValues?.title}</Typography>
      </Stack>
      <Stack spacing={2} direction="column" mt={2}>
        <Typography variant="h6">Description</Typography>
        <Typography variant="body2">{editFormDataValues?.description}</Typography>
      </Stack>

      <Stack spacing={2} direction="row" mt={2} alignItems="center">
        <Typography variant="h6">Progress Status</Typography>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={editFormDataValues?.progress_status}
          value={statusValue ?? editFormDataValues?.progress_status}
          onChange={handleProgressStatusChange}
        >
          <MenuItem value={2}>Approved</MenuItem>
          <MenuItem value={1}>Pending</MenuItem>
          <MenuItem value={0}>Rejected</MenuItem>
        </Select>
      </Stack>
    </>
  );

  useEffect(() => {
    getTenantsData();
  }, []);

  useEffect(() => {
    setTableData({
      ...tableData,
      columns: dataTableColumns,
    });
  }, [dataTableColumns]);

  useEffect(() => {
    getServiceTypeRequestData();
  }, [page, pageSize, filters, sortBy]);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Manage Service Type Requests
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can manage all the service type requests.
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>

          <DataTable
            isLoading={isLoading}
            table={tableData}
            pageSize={pageSize}
            currentPage={page}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
            sorting={sortBy}
            handleSortByChange={handleSortByChange}
            getData={getServiceTypeRequestData}
            handleClearAllFilters={handleClearAllFilters}
          />
        </Card>
      </MDBox>

      <MDModal
        openState={openEditForm}
        handleFormClose={() => {
          setOpenEditForm(false);
        }}
        modalhead="View Request"
      >
        {renderModalView}
      </MDModal>
    </CustomPageLayout>
  );
}

export default ServiceTypeRequests;
