import { callPostApi } from "../interceptor";

// get dashboard data API function
export async function getDashboardDataApi(data) {
  try {
    const response = await callPostApi({ url: "users/dashboard", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}

// get subscriptions listing for dashboard API function
export async function getSubscriptionsListingApi(data) {
  try {
    const response = await callPostApi({ url: "users/upcoming-payments", body: data });
    return response;
  } catch (error) {
    throw error;
  }
}
