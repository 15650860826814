/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
import Profile from "layouts/pages/account/profile";

import Roles from "layouts/pages/roles";
import AddRoles from "layouts/pages/roles/add";

// import AllProjects from "layouts/pages/profile/all-projects";
// import NewUser from "layouts/pages/users/new-user";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "layouts/pages/pricing-page";
// import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
// import Charts from "layouts/pages/charts";
// import Notifications from "layouts/pages/notifications";

// import NewProduct from "layouts/ecommerce/products/new-product";
// import EditProduct from "layouts/ecommerce/products/edit-product";
// import ProductPage from "layouts/ecommerce/products/product-page";
// import OrderList from "layouts/ecommerce/orders/order-list";
// import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignIn from "layouts/authentication/sign-in";
import ForgotPassword from "layouts/authentication/forgot-password";
import OtpVerification from "layouts/authentication/otp-verification";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Profile Picture
import profilePicture from "assets/cc-images/user.png";

import Tenants from "layouts/pages/tenants";
import TenantsDetails from "layouts/pages/tenants/Details";

import RoleDetails from "layouts/pages/roles/details";
import ResetPassword from "layouts/authentication/reset-password";
import Services from "layouts/pages/services";
import AddService from "layouts/pages/services/add";
import EditService from "layouts/pages/services/edit";
import ServiceType from "layouts/pages/service-type";
import FormBuilder from "layouts/pages/formbuilder";
import EditForm from "layouts/pages/formbuilder/EditForm";
import FormList from "components/FormBuilder/FormList";
import Subscriptions from "layouts/pages/subscriptions";
import MasterSettings from "layouts/pages/master-settings";
import SubscriptionDetails from "layouts/pages/subscriptions/Details";
import Plans from "layouts/pages/plans";
import AddPlan from "layouts/pages/plans/add";
import EditPlan from "layouts/pages/plans/edit";
import EmailTemplates from "layouts/pages/email-templates";
import EditEmailTemplate from "layouts/pages/email-templates/edit";
import ServiceTypeRequests from "layouts/pages/service-type/ServiceTypeRequests";
import Coupons from "layouts/pages/coupons";
import AddCoupon from "layouts/pages/coupons/add";
import EditCoupon from "layouts/pages/coupons/edit";
import PlanDetails from "layouts/pages/plans/Details";

const routes = [
  {
    type: "collapse",
    name: "profile_name",
    key: "profile_name",
    icon: <MDAvatar src={profilePicture} alt="Guest User" size="sm" />,
    collapse: [
      {
        name: "Profile",
        key: "profile",
        route: "/profile",
        component: <Profile />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/login",
        icon: <Icon fontSize="medium">logout</Icon>,
        component: <SignIn />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <Analytics />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Roles",
  //   key: "roles",
  //   icon: <Icon fontSize="medium">lock_person</Icon>,
  //   route: "/roles",
  //   component: <Roles />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Tenants",
    key: "tenants",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      {
        name: "List",
        key: "tenants",
        route: "/tenants",
        component: <Tenants />,
      },
      // {
      //   name: "Add New Tenant",
      //   key: "add-tenant",
      //   route: "/add-tenant",
      //   component: <AddTenants />,
      // },
    ],
  },
  {
    name: "Tenant Details",
    key: "tenant_details",
    route: "/tenants/:id",
    component: <TenantsDetails />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Roles",
    key: "roles",
    icon: <Icon fontSize="medium">lock_person</Icon>,
    collapse: [
      {
        name: "List",
        key: "roles",
        route: "/roles",
        component: <Roles />,
      },
      {
        name: "Add New Role",
        key: "add-role",
        route: "/roles/add-role",
        component: <AddRoles />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Service Type",
    key: "service-types",
    icon: <Icon fontSize="medium">design_services</Icon>,
    collapse: [
      {
        name: "List",
        key: "service-types",
        route: "/service-types",
        component: <ServiceType />,
      },
      {
        name: "Service Type Requests",
        key: "requests",
        route: "/service-types/requests",
        component: <ServiceTypeRequests />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Form Builder",
    key: "form-builder",
    icon: <Icon fontSize="medium">dynamic_form</Icon>,
    collapse: [
      {
        name: "List",
        key: "form-builder",
        route: "/form-builder",
        component: <FormList />,
      },
      {
        name: "Create Form",
        key: "create-form",
        route: "/form-builder/create-form",
        component: <FormBuilder />,
      },
    ],
  },
  {
    name: "Edit Form",
    key: "form-builder-editor",
    route: "/form-builder/edit-form/:id",
    component: <EditForm />,
  },

  {
    name: "Role Details",
    key: "role_details",
    route: "/roles/:id",
    component: <RoleDetails />,
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "Services",
  //   key: "services",
  //   icon: <Icon fontSize="medium">business</Icon>,
  //   collapse: [
  //     {
  //       name: "View All Services",
  //       key: "services",
  //       route: "/services",
  //       component: <Services />,
  //     },
  //     {
  //       name: "Add New Service",
  //       key: "add-service",
  //       route: "/services/add-service",
  //       component: <AddService />,
  //     },
  //   ],
  // },
  {
    name: "Edit Service",
    key: "edit_service",
    route: "/services/:id/edit",
    component: <EditService />,
    noCollapse: true,
  },
  {
    name: "forgot",
    key: "forgot_password",
    route: "/forgot-password",
    component: <ForgotPassword />,
    noCollapse: true,
  },
  {
    name: "otp",
    key: "otp",
    route: "/otp-verification",
    component: <OtpVerification />,
    noCollapse: true,
  },
  {
    name: "reset",
    key: "reset_password",
    route: "/reset-password",
    component: <ResetPassword />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Subscription",
    key: "subscription",
    icon: <Icon fontSize="medium">people</Icon>,
    collapse: [
      {
        name: "List",
        key: "subscriptions",
        route: "/subscriptions",
        component: <Subscriptions />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Master Settings",
        key: "settings",
        route: "/settings",
        component: <MasterSettings />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Email Templates",
    key: "email-templates",
    icon: <Icon fontSize="medium">email</Icon>,
    collapse: [
      {
        name: "List",
        key: "email-templates",
        route: "/email-templates",
        component: <EmailTemplates />,
      },
    ],
  },
  {
    name: "Edit Email Template",
    key: "email-template",
    route: "/email-template/:id/edit",
    component: <EditEmailTemplate />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Plans",
    key: "plans",
    icon: <Icon fontSize="medium">business</Icon>,
    collapse: [
      {
        name: "List",
        key: "view-plans",
        route: "/plans/view-plans",
        component: <Plans />,
      },
      {
        name: "Add New Plan",
        key: "add-plan",
        route: "/plans/add-plan",
        component: <AddPlan />,
      },
    ],
  },
  {
    name: "Subscription Details",
    key: "subscription_details",
    route: "/subscriptions/:id",
    component: <SubscriptionDetails />,
    noCollapse: true,
  },
  {
    name: "Edit Plan",
    key: "edit_plan",
    route: "/plans/:id/edit",
    component: <EditPlan />,
    noCollapse: true,
  },
  {
    name: "Plan Details",
    key: "plans_details",
    route: "/plans/:id",
    component: <PlanDetails />,
    noCollapse: true,
  },



  {
    type: "collapse",
    name: "Coupons",
    key: "coupons",
    icon: <Icon fontSize="medium">discount</Icon>,
    collapse: [
      {
        name: "List",
        key: "coupons",
        route: "/coupons",
        component: <Coupons />,
      },
      {
        name: "Add New Coupons",
        key: "add-coupons",
        route: "/coupons/add-coupons",
        component: <AddCoupon />,
      },
    ],
  },
  {
    name: "Edit Coupon",
    key: "edit_coupon",
    route: "/coupons/:id/edit",
    component: <EditCoupon />,
    noCollapse: true,
  },

  
];

export default routes;

export function getModuleNameFromURL(path) {
  let module;
  routes.forEach((route) => {
    route.children.forEach((child) => {
      if (child.path === path) module = child.moduleName;
    });
  });

  return module;
}

export function getAccessByModuleName(modules, moduleName) {
  return modules.find((module) => module.name === moduleName);
}
