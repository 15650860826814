import { CommonContextProvider } from "./CommonCtx";
import { ModalContextProvider } from "./ModalCtx";
import { LoginContext } from "./loginFormCtx";

// eslint-disable-next-line react/prop-types
export const MainContext = ({ children }) => {
  return (
    <LoginContext>
      <CommonContextProvider>
        <ModalContextProvider>
          <>{children}</>
        </ModalContextProvider>
      </CommonContextProvider>
    </LoginContext>
  );
};
