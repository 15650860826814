import { callDeleteApi, callGetApi, callPostApi, callPutApi } from "../interceptor";

// Get Subscriptions List API function
export async function getSubscriptions(params) {
  try {
    const response = await callGetApi({ url: "subscription/list", params });
    return response;
  } catch (error) {
    throw error;
  }
}

// Get Subscription By Id API function
export async function getSubscriptionById(id) {
  try {
    const response = await callGetApi({ url: `subscription/${id}` });
    return response;
  } catch (error) {
    throw error;
  }
}
