import { LOCAL_STORAGE } from "utils/constants";

const FiltersKey = LOCAL_STORAGE.FILTERS;
const SortByKey = LOCAL_STORAGE.SORT_BY;

// Get filters from LocalStorage
export const getFiltersFromCache = (tableName) => {
  const allFilters = JSON.parse(localStorage.getItem(FiltersKey));

  if (!allFilters) {
    return null;
  }

  const tableFilters = allFilters[tableName] || null;
  return tableFilters;
};

// Save filters to LocalStorage
export const saveFiltersToCache = (tableName, filters) => {
  const currentAllFilters = JSON.parse(localStorage.getItem(FiltersKey));
  const newAllFilters = {
    ...currentAllFilters,
    [tableName]: filters,
  };
  localStorage.setItem(FiltersKey, JSON.stringify(newAllFilters));
  return newAllFilters;
};

// get SortBy from LocalStorage
export const getSortByFromCache = (tableName) => {
  const allSortBy = JSON.parse(localStorage.getItem(SortByKey));

  if (!allSortBy) {
    return null;
  }

  const tableSortBy = allSortBy[tableName] || null;
  return tableSortBy;
};

// Save SortBy to LocalStorage
export const saveSortByToCache = (tableName, sortBy) => {
  const currentAllSortBy = JSON.parse(localStorage.getItem(SortByKey));
  const newAllSortBy = {
    ...currentAllSortBy,
    [tableName]: sortBy,
  };
  localStorage.setItem(SortByKey, JSON.stringify(newAllSortBy));
  return newAllSortBy;
};
