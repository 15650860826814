// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Data
import { Button, FormHelperText, Grid, Typography } from "@mui/material";

import { useContext, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { Divider } from "rsuite";
import { LoginFormContext } from "context/loginFormCtx";
import { TinyTextEditor } from "components/TinyTextEditor";
import { updateEmailTemplate } from "lib/Api/EmailTemplates";

function EditEmailTemplateForm({ emailTemplateData }) {
  const { setNotification } = useContext(LoginFormContext);

  const navigate = useNavigate();

  const [textEditorValue, setTextEditorValue] = useState(
    emailTemplateData?.template_content || null
  );
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async () => {
    try {
      setDisabled(true);
      if (!textEditorValue) {
        setError("Template Content is required");
        setDisabled(false);
        return;
      }

      const body = {
        template_name: emailTemplateData?.template_name,
        template_content: textEditorValue,
      };
      const response = await updateEmailTemplate(emailTemplateData?.id, body);
      if (response?.status === 200) {
        setNotification({
          color: "success",
          icon: "check",
          title: "Success",
          content: "Email Template Updated Successfully!",
          open: true,
        });
        navigate("/email-templates");
      } else {
        setNotification({
          color: "error",
          icon: "cancel",
          title: "Error",
          content: response?.data?.message || "Something went wrong!",
          open: true,
        });
      }

      setDisabled(false);
    } catch (error) {
      setNotification({
        color: "error",
        icon: "cancel",
        title: "Error",
        content: error?.response?.data?.message || "Something went wrong!",
        open: true,
      });
    }
  };

  return (
    <>
      <Typography variant="h5" fontWeight="medium" sx={{ mb: 3, mt: -3 }}>
        {emailTemplateData?.template_label}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TinyTextEditor
            setContent={(value) => {
              setTextEditorValue(value);
            }}
            content={emailTemplateData?.template_content}
          />
        </Grid>
      </Grid>

      {error ? <FormHelperText sx={{ color: "red" }}>{error}</FormHelperText> : null}

      <Divider />
      <MDBox sx={{ mt: 2 }} display="flex" justifyContent="center" alignItems="center">
        <Button disabled={disabled} sx={{ m: 1 }} onClick={handleSubmit} variant="contained">
          Save
        </Button>
        <Link to="/email-templates">
          <Button variant="outlined">Cancel</Button>
        </Link>
      </MDBox>
    </>
  );
}

export default EditEmailTemplateForm;
