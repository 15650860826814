import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

//  GET API
export async function callGetApi({ url, body, headers }) {
  try {
    const authHeader = localStorage.getItem("authorization")
      ? { authorization: localStorage.getItem("authorization") }
      : {};

    const result = await axios({
      url: API_URL + url,
      method: "GET",
      headers: { ...authHeader, ...headers },
      data: body,
      timeout: 120000,
    });
    return result;
  } catch (error) {
    // throw error;
    if (error && error.response) {
      return error.response;
    }
  }
}

//  POST API
export async function callPostApi({ url, body, headers }) {
  try {
    const authHeader = localStorage.getItem("authorization")
      ? { authorization: localStorage.getItem("authorization") }
      : {};

    const result = await axios({
      url: API_URL + url,
      method: "POST",
      headers: { ...authHeader, ...headers },
      data: body,
      timeout: 120000,
    });
    return result;
  } catch (error) {
    // throw error;
    if (error && error.response) {
      return error.response;
    }
  }
}

// PUT API
export async function callPutApi({ url, body, headers }) {
  try {
    const authHeader = localStorage.getItem("authorization")
      ? { authorization: localStorage.getItem("authorization") }
      : {};

    const result = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: { ...authHeader, ...headers },
      data: body,
      timeout: 120000,
    });
    return result;
  } catch (error) {
    // throw error;
    if (error && error.response) {
      return error.response;
    }
  }
}

// DELETE API
export async function callDeleteApi({ url, body, headers }) {
  try {
    const authHeader = localStorage.getItem("authorization")
      ? { authorization: localStorage.getItem("authorization") }
      : {};

    const result = await axios({
      url: API_URL + url,
      method: "DELETE",
      headers: { ...authHeader, ...headers },
      data: body,
      timeout: 120000,
    });
    return result;
  } catch (error) {
    // throw error;
    if (error && error.response) {
      return error.response;
    }
  }
}
