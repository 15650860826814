// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import CustomPageLayout from "examples/LayoutContainers/CustomPageLayout";
import { Grid } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LoginFormContext } from "context/loginFormCtx";
import { getCouponById } from "lib/Api/Coupons";
import AddEditCouponForm from "components/Coupons/addEditForm";

function EditCoupon() {

  const { id } = useParams();

  const [couponData, setCouponData] = useState(null);

  const navigate = useNavigate();

  const { setNotification } = useContext(LoginFormContext);

  const getCouponData = async () => {
    try {
      const response = await getCouponById(id);

      if (response?.status === 200) {
        const resData = response?.data?.data;
        setCouponData(resData);
      } else {
        setNotification({
          color: "error",
          icon: "check",
          title: "Error",
          content: response?.data?.message,
          open: true,
        });
        navigate("/coupons");
      }
    } catch (error) {
      console.log(error);
      setNotification({
        color: "error",
        icon: "check",
        title: "Error",
        content: error?.message || "Something went wrong!",
        open: true,
      });
    }
  };

  useEffect(() => {
    getCouponData();
  }, [id]);

  return (
    <CustomPageLayout>
      <MDBox pt={3} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <MDTypography variant="h5" fontWeight="medium">
                  Edit Coupon
                </MDTypography>
                <MDTypography variant="button" color="text">
                  Here you can edit your Coupon.
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            {couponData && <AddEditCouponForm couponData={couponData} />}
          </MDBox>
        </Card>
      </MDBox>
    </CustomPageLayout>
  );
}

export default EditCoupon;
